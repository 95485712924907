import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useState } from 'react';

import { ChatMessageType, UserChatMessageType, mutateAIChat } from '@/api/ai';
import AIStarsIcon from '@/assets/svg/ai-stars.svg?react';
import { AISearchInput } from '@/components/Form';
import { Button } from '@/components/buttons';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import { WebSocketError, WebSocketResponse, useAuthWebSocket } from '@/hooks/useAuthWebSocket';

import AIDrawer from './AIDrawer';
import styles from './AISearch.module.scss';

const AISearch = () => {
  const canUseWebsockets = useFeatureFlag(FEATURE_FLAG.websockets);
  const [chatSessionId, setChatSessionId] = useState<string>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [isAIResponseLoading, setAIResponseLoading] = useState(false);
  const [isUserChatDisabled, setUserChatDisabled] = useState(false);

  const handleMessageReceived = (wsMessage: WebSocketResponse) => {
    if (wsMessage.type !== 'chunk') {
      setChatSessionId(wsMessage.payload.sessionId);
      setUserChatDisabled(false);
    }
    setAIResponseLoading(false);
    setMessages((prevMessages) => {
      const sessionId = wsMessage.type === 'chunk' ? chatSessionId : wsMessage.payload.sessionId;

      if (!prevMessages.length) {
        return [
          {
            content: wsMessage.payload.content,
            role: 'ai',
            isComplete: wsMessage.type === 'complete',
            sessionId,
          },
        ];
      } else {
        const lastMessage = prevMessages[prevMessages.length - 1];

        if (lastMessage.role === 'ai' && !lastMessage.isComplete) {
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              content: lastMessage.content + wsMessage.payload.content,
              isComplete: wsMessage.type === 'complete',
              sessionId,
            },
          ];
        } else {
          return [
            ...prevMessages,
            {
              content: wsMessage.payload.content,
              role: 'ai',
              isComplete: wsMessage.type === 'complete',
              sessionId,
            },
          ];
        }
      }
    });
  };

  const handleErrorReceived = (wsMessage: WebSocketError) => {
    setUserChatDisabled(false);
    setAIResponseLoading(false);
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const errorMessage: ChatMessageType = {
        content: t`An error occurred while processing your request. Please contact support if the issue persists.`,
        role: 'error',
        sessionId: wsMessage.payload.sessionId,
      };

      if (lastMessage.role === 'ai' && !lastMessage.isComplete) {
        // if we were streaming and encountered an error, replace the accumulated streamed message
        // with the error message
        return [...prevMessages.slice(0, -1), errorMessage];
      } else {
        return [...prevMessages, errorMessage];
      }
    });
  };

  const { onSendMessage } = useAuthWebSocket({
    onMessageReceived: handleMessageReceived,
    onErrorReceived: handleErrorReceived,
  });

  const sendMessage = async (message: string) => {
    const userMessage: UserChatMessageType = {
      content: message,
      role: 'user',
      sessionId: chatSessionId,
    };
    setMessages((prev) => [...prev, userMessage]);
    setAIResponseLoading(true);
    setUserChatDisabled(true);

    if (canUseWebsockets) {
      onSendMessage?.({ content: message, sessionId: chatSessionId });
    } else {
      const aiResponse = await mutateAIChat(message);
      setMessages((prev) => [
        ...prev,
        {
          content: aiResponse,
          role: 'ai',
          isComplete: true,
          sessionId: '', // The deprecated Sol chat doesn't have a session ID, only Nebula's does
        },
      ]);
      setAIResponseLoading(false);
      setUserChatDisabled(false);
    }
  };

  const handleSearch = (value: string) => {
    setDrawerOpen(true);
    sendMessage(value);
  };

  const handleSendMessage = (message: string) => {
    sendMessage(message);
  };

  return (
    <div className={styles.container}>
      {messages.length === 0 ? (
        <AISearchInput
          className={styles.searchInput}
          size="medium"
          isSearchDisabled={isUserChatDisabled}
          isRound
          onSearch={handleSearch}
        />
      ) : (
        <Button
          className={styles.openButton}
          variant="secondary"
          color="black"
          icon={<AIStarsIcon />}
          onClick={() => setDrawerOpen(true)}
        >
          <Trans>Open Conversation</Trans>
        </Button>
      )}
      <AIDrawer
        open={isDrawerOpen}
        messages={messages}
        isAIResponseLoading={isAIResponseLoading}
        isUserChatDisabled={isUserChatDisabled}
        onClose={() => setDrawerOpen(false)}
        onSendMessage={handleSendMessage}
      />
    </div>
  );
};

export default AISearch;
