import { t } from '@lingui/core/macro';
import { useRef, useState } from 'react';

import { BenchmarksByChannelDataType } from '@/api/benchmarks';
import AutoSizer from '@/components/AutoSizer';
import { Divider } from '@/components/Divider';
import BarLoader from '@/components/Flare/loaders/BarLoader';
import { Legend, LegendItem } from '@/components/Legend';
import Spin from '@/components/Spin';
import WidgetError from '@/components/errors/WidgetError';
import { TAM_PRIMARY, VISITS_PRIMARY } from '@/constants/colors';
import SolAPIError from '@/error/SolAPIError';
import { numberFormat } from '@/helper/numberFormatter';

import BenchmarksBar from './BenchmarksBar';
import styles from './BenchmarksChart.module.scss';
import BenchmarksChartAxis from './BenchmarksChartAxis';
import BenchmarksTooltip from './BenchmarksTooltip';

type Props = {
  data?: BenchmarksByChannelDataType;
  isLoading?: boolean;
  error: SolAPIError | null;
};

const BenchmarksChart = ({ data, isLoading, error }: Props) => {
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
  const stickyData = useRef(data);

  if (error) {
    return <WidgetError className={styles.errorContainer} />;
  }

  if (data != null) {
    stickyData.current = data;
  }

  const showSkeletonLoader = isLoading && stickyData.current == null;
  const showSpinnerLoader = isLoading && stickyData.current != null;

  return (
    <AutoSizer className={styles.autosizer} disableHeight>
      {({ width: measuredWidth }) => (
        <div className={styles.container}>
          <Legend className={styles.legend} noToggleVisibility>
            <LegendItem value="tam" color={TAM_PRIMARY} name={t`Your TAM Performance`} />
            <LegendItem value="visits" color={VISITS_PRIMARY} name={t`Industry Average`} />
          </Legend>
          {showSkeletonLoader ? (
            <div className={styles.loadingContainer}>
              <BarLoader numSeries={1} numCategories={8} sortDescending />
            </div>
          ) : (
            <Spin spinning={!!showSpinnerLoader && !error}>
              <div className={styles.chartContainer}>
                <BenchmarksChartAxis width={measuredWidth} />
                <div className={styles.plotArea}>
                  <div>
                    <BenchmarksBar
                      label={t`All Channels`}
                      width={measuredWidth}
                      barLabel={t`${numberFormat(stickyData.current?.benchmarks.customer, {
                        isPercent: true,
                        precision: 0,
                      })} from TAM`}
                      value={stickyData.current?.benchmarks.customer ?? 0}
                      target={stickyData.current?.benchmarks.industry ?? 0}
                      isMuted={hoveredRowId != null && hoveredRowId !== 'all'}
                      onMouseEnter={() => setHoveredRowId('all')}
                      onMouseLeave={() => setHoveredRowId(null)}
                    >
                      <BenchmarksTooltip
                        title={t`All Channels`}
                        customerBenchmark={stickyData.current?.benchmarks.customer}
                        industryBenchmark={stickyData.current?.benchmarks.industry}
                        open={hoveredRowId === 'all'}
                      />
                    </BenchmarksBar>
                    <Divider className={styles.divider} dashed />
                  </div>
                  {stickyData.current?.data.map((d) => (
                    <BenchmarksBar
                      key={d.channel.id}
                      width={measuredWidth}
                      label={d.channel.name}
                      barLabel={t`${numberFormat(d.customerVisits.benchmark, {
                        isPercent: true,
                        precision: 0,
                      })} from TAM`}
                      value={d.customerVisits.benchmark}
                      target={d.industryVisits.benchmark}
                      isMuted={hoveredRowId != null && hoveredRowId !== d.channel.id}
                      onMouseEnter={() => setHoveredRowId(d.channel.id)}
                      onMouseLeave={() => setHoveredRowId(null)}
                    >
                      <BenchmarksTooltip
                        title={d.channel.name}
                        customerBenchmark={d.customerVisits.benchmark}
                        industryBenchmark={d.industryVisits.benchmark}
                        open={hoveredRowId === d.channel.id}
                      />
                    </BenchmarksBar>
                  ))}
                </div>
              </div>
            </Spin>
          )}
        </div>
      )}
    </AutoSizer>
  );
};

export default BenchmarksChart;
