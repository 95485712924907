import { t } from '@lingui/core/macro';
import { Fragment } from 'react/jsx-runtime';

import { Text } from '@/components/typography';

const MAX_EVENTS = 5;

type Props = {
  events: { id: string; date: string }[];
};

const TimelineChartTooltipEventLinks = ({ events }: Props) => {
  if (events.length === 1) {
    return (
      <Text id="https://www.adobe.com" variant="body2" color="white" underline pointer>
        {t`View`}
      </Text>
    );
  } else {
    const displayEvents = events.slice(0, MAX_EVENTS);

    return (
      <>
        {displayEvents.map((event, index) => (
          <Fragment key={event.id}>
            <span>{index > 0 && ', '}</span>
            <Text id="https://www.adobe.com" variant="body2" color="white" underline pointer>
              #{index + 1}
            </Text>
          </Fragment>
        ))}
        {events.length > MAX_EVENTS && (
          <Text variant="body2" color="white">
            {' '}
            (of {events.length})
          </Text>
        )}
      </>
    );
  }
};

export default TimelineChartTooltipEventLinks;
