import { AxiosProgressEvent } from 'axios';
import gql from 'graphql-tag';
import { useMemo } from 'react';

import { InstantSearchSortOrder, QueryState, SolQueryParams } from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { useSolImmutableQuery, useSolInfiniteQuery, useSolQuery } from '@/hooks/useSolQuery';

import fetcher from '../Fetcher';
import { fetcherSol } from '../sol-fetcher';
import { SolDeleteVariablesType } from '../solType';
import getVariablesFromTableParams, {
  getVariablesFromTableParamsNew,
} from '../util/getVariablesFromTableParams';
import {
  AudienceCreateResponseType,
  AudienceCreateType,
  AudienceCreateVariablesType,
  AudienceDatasetResponse,
  AudienceDeleteResponseType,
  AudienceGQLResponse,
  AudienceListGQLResponse,
  AudienceMembershipGQLResponse,
  AudienceOptionGQLResponseType,
  AudienceOptionType,
  AudienceUpdateResponseType,
  AudienceUpdateType,
  AudienceUpdateVariablesType,
  SalesforceAudienceReportMetadataGQLResponse,
  UploadAudienceProgressCallback,
} from './audienceType';

const CSV_AUDIENCE_HEADER = 'domain';

export const uploadAudienceFile = async (
  file: File | Blob,
  onUploadProgress?: UploadAudienceProgressCallback,
): Promise<string> => {
  const name = file instanceof File ? file.name : 'audience.csv';

  const response = await fetcher.requestSol<File | Blob, AudienceDatasetResponse>({
    url: 'audiences/dataset',
    method: 'POST',
    data: file,
    headers: {
      'Content-Type': file.type,
      'Content-Disposition': `attachment; filename="${name}"`,
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (!onUploadProgress) {
        return;
      }

      if (progressEvent.total && progressEvent.total !== 0) {
        const percentComplete = Math.round(progressEvent.loaded / progressEvent.total);
        onUploadProgress(percentComplete);
      } else {
        onUploadProgress(1);
      }
    },
  });

  return response.data.dataset;
};

export const uploadAudienceDomains = (domains: string[]) => {
  const file = new Blob([[CSV_AUDIENCE_HEADER, ...domains].join('\n')], { type: 'text/csv' });
  return uploadAudienceFile(file);
};

const DEFAULT_FILTER_FIELDS = ['created_by.name', 'description', 'name'];

export const useAudienceOptions = ({
  search = '',
  pageSize = 50,
  prependedOptions = [],
}: {
  search?: string;
  pageSize?: number;
  prependedOptions?: AudienceOptionType[];
}) => {
  const { data, isLoading, isFetchingNextPage, error, fetchNextPage } =
    useSolInfiniteQuery<AudienceOptionGQLResponseType>({
      queryKey: ['audienceOptions', search],
      queryFn: ({ pageParam = 0, signal }) => {
        return {
          query: gql`
            query GetAudienceOptions(
              $page: Pagination!
              $sort: [SortParamInput]!
              $filter: [FilterParamInput]!
              $queryId: String
            ) {
              audiences {
                get(page: $page, sort: $sort, filter: $filter, queryId: $queryId) {
                  edges {
                    node {
                      id
                      name
                      metrics {
                        size
                      }
                    }
                  }
                  pageMeta {
                    hasNext
                  }
                }
              }
            }
          `,
          variables: getVariablesFromTableParams(['name'], {
            page: {
              offset: pageParam * pageSize,
              limit: pageSize,
            },
            sort: {
              direction: InstantSearchSortOrder.ASC,
              field: 'name',
            },
            filter: search,
          }),
          signal,
        };
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.audiences.get.pageMeta.hasNext ? pages.length + 1 : undefined;
      },
    });

  const hasMore = data?.pages?.[data.pages.length - 1].audiences.get.pageMeta.hasNext;

  const audienceOptions = useMemo(() => {
    if (!data) {
      return prependedOptions;
    }

    return (prependedOptions || []).concat(
      data.pages.map((d) => d.audiences.get.edges.map((edge) => edge.node)).flat(),
    );
  }, [data, prependedOptions]);

  return {
    audienceOptions,
    hasMore,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
  };
};

export const useAudienceList = (tableParams?: SolQueryParams) => {
  const { data, error, isLoading, mutate } = useSolQuery<AudienceListGQLResponse>({
    query:
      tableParams &&
      gql`
        query GetAudienceList(
          $page: Pagination!
          $sort: [SortParamInput]!
          $filter: [FilterParamInput]!
        ) {
          audiences {
            get(page: $page, sort: $sort, filter: $filter) {
              edges {
                node {
                  id
                  name
                  description
                  type
                  last_job {
                    status
                    errors
                  }
                  metrics {
                    size
                    visits
                  }
                  created_at
                  created_by {
                    id
                    name
                  }
                }
              }
              totalEdges
            }
          }
        }
      `,
    variables: getVariablesFromTableParams(DEFAULT_FILTER_FIELDS, tableParams),
  });

  return {
    audienceListData: data?.audiences.get.edges.map((edge) => edge.node),
    totalResults: data?.audiences.get.totalEdges,
    isLoading,
    error,
    mutate,
  };
};

export const useAudienceById = (audienceId?: string) => {
  const { data, error, isLoading, mutate } = useSolQuery<AudienceGQLResponse>({
    query:
      !!audienceId &&
      gql`
        query GetAudience($getByIdId: String) {
          audiences {
            getById(id: $getByIdId) {
              id
              description
              last_job {
                status
              }
              metrics {
                size
                visits
              }
              name
              salesforce_url
              type
            }
          }
        }
      `,
    variables: {
      getByIdId: audienceId,
    },
  });

  return {
    audience: data?.audiences.getById,
    error,
    isLoading,
    mutate,
  };
};

export const mutateAudienceCreate = async (audience: AudienceCreateType) => {
  const variables: AudienceCreateVariablesType = {
    audience: {
      description: audience.description,
      name: audience.name,
      type: audience.type,
    },
  };

  if (audience.type === 'salesforce') {
    variables.audience.salesforce_url = audience.salesforce_url;
  }

  if (audience.type === 'upload') {
    variables.audience.dataset_id = audience.dataset_id;
  }

  return await fetcherSol<AudienceCreateVariablesType, AudienceCreateResponseType>({
    query: gql`
      mutation CreateAudience($audience: CreateAudienceInput!) {
        audiences {
          create(audience: $audience) {
            id
            description
            last_job {
              status
            }
            metrics {
              size
              visits
            }
            name
            salesforce_url
            type
          }
        }
      }
    `,
    variables,
  });
};

export const mutateAudienceUpdate = async (audience: AudienceUpdateType) => {
  return await fetcherSol<AudienceUpdateVariablesType, AudienceUpdateResponseType>({
    query: gql`
      mutation UpdateAudience($audience: UpdateAudienceInput!) {
        audiences {
          update(audience: $audience) {
            id
            description
            last_job {
              status
            }
            metrics {
              size
              visits
            }
            name
            salesforce_url
            type
          }
        }
      }
    `,
    variables: {
      audience: {
        id: audience.id,
        description: audience.description,
        name: audience.name,
      },
    },
  });
};

export const mutateAudienceDelete = async (audienceId: string) => {
  const response = await fetcherSol<SolDeleteVariablesType, AudienceDeleteResponseType>({
    query: gql`
      mutation AudienceDelete($deleteId: String!) {
        audiences {
          delete(id: $deleteId) {
            id
            success
          }
        }
      }
    `,
    variables: {
      deleteId: audienceId,
    },
  });

  return response.audiences.delete.success;
};

export const useAudienceMembership = (
  audienceId: string | undefined,
  membershipQueryState: QueryState | undefined,
) => {
  const tableParams = getSolQueryParamsNewFromQueryState(membershipQueryState);

  const { data, error, isLoading } = useSolQuery<AudienceMembershipGQLResponse>({
    query:
      tableParams &&
      gql`
        query GetByAudienceId(
          $audienceId: String!
          $sort: [SortParamInput]!
          $page: Pagination!
          $searchQuery: String
        ) {
          companies {
            getByAudienceId(
              audienceId: $audienceId
              sort: $sort
              page: $page
              searchQuery: $searchQuery
            ) {
              edges {
                node {
                  id
                  name
                  tld
                  metrics {
                    visits
                    lastActivityDate
                  }
                }
              }
              totalEdges
            }
          }
        }
      `,
    variables: {
      audienceId,
      ...getVariablesFromTableParamsNew(tableParams),
    },
  });

  return {
    audienceMembership: data?.companies.getByAudienceId.edges.map((edge) => edge.node),
    totalResults: data?.companies.getByAudienceId.totalEdges,
    isLoading,
    error,
  };
};

export const useSalesforceAudienceReportMetadata = (reportURL?: string) => {
  const { data, error, isLoading } =
    useSolImmutableQuery<SalesforceAudienceReportMetadataGQLResponse>(
      {
        query:
          !!reportURL &&
          gql`
            query GetAudienceReportMetadata($url: String!) {
              audiences {
                getAudienceReportMetadata(url: $url) {
                  name
                }
              }
            }
          `,
        variables: {
          url: reportURL,
        },
      },
      { retry: false },
    );

  return {
    reportMetadata: data?.audiences.getAudienceReportMetadata,
    isLoading,
    error,
  };
};
