import gql from 'graphql-tag';
import { useMemo } from 'react';

import { useSolQuery } from '@/hooks/useSolQuery';

import { BenchmarksByChannelGQLResponse } from './benchmarksType';

export const useBenchmarksByChannel = (startDate?: string, endDate?: string) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSolQuery<BenchmarksByChannelGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query BenchmarksByChannel($startDate: DateTime, $endDate: DateTime) {
          visualization {
            benchmarksByChannel(startDate: $startDate, endDate: $endDate) {
              benchmarks {
                industry
                customer
              }
              data {
                channel {
                  id
                  name
                }
                industryVisits {
                  tam
                  benchmark
                }
                customerVisits {
                  tam
                  benchmark
                }
              }
            }
          }
        }
      `,
    variables: {
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  const sortedData = useMemo(() => {
    if (!data?.visualization.benchmarksByChannel) {
      return undefined;
    }

    const { benchmarks, data: channelData } = data.visualization.benchmarksByChannel;
    return {
      benchmarks,
      data: channelData.sort((a, b) => b.customerVisits.benchmark - a.customerVisits.benchmark),
    };
  }, [data]);

  return {
    benchmarksByChannel: sortedData,
    isLoading,
    error,
  };
};
