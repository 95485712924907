import { t } from '@lingui/core/macro';
import moment from 'moment';
import { useMemo } from 'react';

import { AudienceOptionType } from '@/api/audience';
import { TRAFFIC_TYPES, VisitTrafficTrendType } from '@/api/common';
import Flare, {
  Area,
  Axis,
  FlareError,
  FlareSeriesOptions,
  Legend,
  Notes,
  Tooltip,
} from '@/components/Flare';
import {
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  TAM_PRIMARY,
  UNKNOWN_PRIMARY,
  VISITS_PRIMARY,
} from '@/constants/colors';
import { DateFormatMD, MonthDateYearFormat } from '@/constants/formats';
import { getUTCTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  data?: VisitTrafficTrendType[];
  audience?: AudienceOptionType;
  isLoading?: boolean;
  error: FlareError;
};

const WebTrafficVisitByTypeTrendChart = ({ data, audience, isLoading, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: TRAFFIC_TYPES.BOT,
        name: t`Bot`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.botVisits })),
      },
      {
        id: TRAFFIC_TYPES.UNRESOLVED,
        name: t`Unknown`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.unresolvedVisits })),
      },
      {
        id: TRAFFIC_TYPES.OTHER,
        name: t`Other Companies`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.otherVisits })),
      },
      {
        id: TRAFFIC_TYPES.TARGET,
        name: audience?.id ? audience.name : t`TAM`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.targetVisits })),
      },
    ];
  }, [data, audience]);

  return (
    <Flare
      data={seriesData}
      width="auto"
      height="auto"
      colors={[
        BOT_PRIMARY,
        UNKNOWN_PRIMARY,
        VISITS_PRIMARY,
        audience?.id ? AUDIENCE_PRIMARY : TAM_PRIMARY,
      ]}
      parseX={getUTCTime}
      description={t`A trend chart showing the number of visits by traffic type: Audience or TAM, Other, and Bot`}
      isLoading={isLoading}
      error={error}
    >
      <Legend reversed />
      <Area x="date" y="amount" position="stack" fillOpacity={0.85} />
      <Axis position="left" labelFormat={(item) => numberFormat(item.value)} title={t`Visits`} />
      <Axis
        type="datetime"
        position="bottom"
        crosshair="line"
        labelFormat={(item) => moment(item.value).format(DateFormatMD)}
      />
      <Tooltip
        shared
        titleFormat={(point) => moment(point?.x).format(MonthDateYearFormat)}
        rowValueFormat={(point) => numberFormat(point?.y)}
        rowSecondaryValueFormat={(point) => {
          if (point != null && point.total && point.y) {
            return t`(${numberFormat(point.y / point.total, { isPercent: true, precision: 1 })})`;
          }
          return undefined;
        }}
        rowValueLink={(point) => {
          const seriesId = point?.series?.userOptions?.id;
          if (seriesId === TRAFFIC_TYPES.TARGET) {
            return ROUTES.webTrafficActivityWithParams({
              audienceIds: audience?.id,
              inTam: audience?.id ? undefined : 'true',
              'metrics.visits': '[1,]',
            });
          } else if (seriesId === TRAFFIC_TYPES.OTHER) {
            return ROUTES.webTrafficActivityWithParams({
              'audienceIds!': audience?.id,
              inTam: audience?.id ? undefined : 'false',
              'metrics.visits': '[1,]',
            });
          } else if (point?.key === 'total') {
            return ROUTES.webTrafficActivityWithParams({
              'metrics.visits': '[1,]',
            });
          }
        }}
        showTotalRow
        unstable_hasRouterContext={false}
      />
      <Notes />
    </Flare>
  );
};

export default WebTrafficVisitByTypeTrendChart;
