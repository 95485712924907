import { ASTNode, print } from 'graphql';
import {
  GetNextPageParamFunction,
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from 'react-query';

import { fetcherSol } from '@/api/sol-fetcher';
import SolAPIError from '@/error/SolAPIError';

type SolQueryConfig<TVariables> = {
  query?: ASTNode | false;
  variables?: TVariables;
};

export const QUERY_DEFAULTS = {
  retryOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
  refetchInterval: 7_200_000, // 2 hours
};

export const useSolImmutableQuery = <TData = unknown, TVariables = unknown>(
  data: SolQueryConfig<TVariables>,
  options: UseQueryOptions<TData, SolAPIError> = {},
) => {
  return useSolQuery<TData, TVariables>(data, {
    ...options,
    staleTime: Infinity,
  });
};

export const useSolQuery = <TData = unknown, TVariables = unknown>(
  data: SolQueryConfig<TVariables>,
  options: UseQueryOptions<TData, SolAPIError> = {},
) => {
  const queryClient = useQueryClient();
  const queryText = data.query && print(data.query);
  const keys = data.variables ? [queryText, data.variables] : [queryText];

  const response = useQuery<TData, SolAPIError>({
    ...options,
    queryKey: keys,
    queryFn: ({ signal }) =>
      fetcherSol<TVariables, TData>({ query: queryText, variables: data.variables }, { signal }),
    enabled: !!data.query,
  });

  return {
    ...response,
    mutate: (newData?: TData, { revalidate = true } = {}) => {
      if (newData) {
        queryClient.setQueryData(keys, newData);
      }

      if (revalidate) {
        response.refetch();
      }
    },
  };
};

type SolInfiniteQueryParams<TData, TVariables> = {
  queryKey: QueryKey;
  queryFn: (
    context: QueryFunctionContext<QueryKey>,
  ) => SolQueryConfig<TVariables> | Promise<SolQueryConfig<TVariables>>;
  getNextPageParam: GetNextPageParamFunction<TData>;
};

export const useSolInfiniteQuery = <TData = unknown, TVariables = unknown>({
  queryKey,
  queryFn,
  getNextPageParam,
}: SolInfiniteQueryParams<TData, TVariables>) => {
  return useInfiniteQuery<TData, SolAPIError>({
    queryKey,
    queryFn: async (context) => {
      const response = await queryFn(context);
      return fetcherSol<TVariables, TData>(response);
    },
    getNextPageParam,
  });
};
