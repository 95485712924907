import { t } from '@lingui/core/macro';
import { ASTNode, print } from 'graphql';
import gql from 'graphql-tag';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { QueryStateFilter } from '@/components/InstantSearch';
import { isFilterRange } from '@/components/InstantSearch/util/filter-util';
import SolAPIError from '@/error/SolAPIError';
import { useSolQuery } from '@/hooks/useSolQuery';
import { useNotification } from '@/providers/Notification';

import { fetcherSol } from '../sol-fetcher';
import {
  CSVJobStatus,
  DisplayNamesGQLResponse,
  DownloadCSVBeginGQLResponse,
  DownloadCSVStatusGQLResponse,
  TrafficVisitTrendGQLResponse,
} from './commonType';

export const useDisplayName = (id?: string | false | null) => {
  const { displayNames, isLoading, error } = useDisplayNames(id ? [id] : undefined);

  return {
    displayName: displayNames?.[0].name,
    isLoading,
    error,
  };
};

export const useDisplayNames = (ids?: string[]) => {
  const { data, isLoading, error } = useSolQuery<DisplayNamesGQLResponse>({
    query:
      !!ids?.length &&
      gql`
        query DisplayNames($ids: [String!]!) {
          displayNames {
            get(ids: $ids) {
              id
              name
            }
          }
        }
      `,
    variables: {
      ids,
    },
  });

  return {
    displayNames: data?.displayNames.get,
    isLoading,
    error,
  };
};

export const useFilterNames = (filters: QueryStateFilter[]) => {
  const filterIds = filters.reduce((memo: string[], filter) => {
    if (isFilterRange(filter)) {
      return memo;
    }

    let ids = [];
    if (!Array.isArray(filter.operand)) {
      ids = [filter.operand];
    } else {
      ids = filter.operand;
    }

    return memo.concat(ids.filter((id) => id.includes('_') || id.includes(':')));
  }, []);

  const { displayNames, isLoading, error } = useDisplayNames(filterIds);

  const filtersWithNames = useMemo(() => {
    if (!displayNames) {
      return filters;
    }

    const idLookup = displayNames.reduce((memo: Record<string, string>, { id, name }) => {
      memo[id] = name;
      return memo;
    }, {});

    // replace filter with id operands with name operands
    return filters.map((filter) => {
      if (isFilterRange(filter)) {
        return filter;
      }

      return {
        ...filter,
        operand: filter.operand.map((id) => idLookup[id] || id),
      };
    });
  }, [displayNames, filters]);

  return {
    filtersWithNames,
    isLoading,
    error,
  };
};

type DownloadCSVInput = {
  filename: string;
  query: ASTNode;
  variables: object;
};

const fetchBeginDownloadCSV = async ({ filename, query, variables }: DownloadCSVInput) => {
  const payload = JSON.stringify({
    query: print(query),
    variables,
  });

  const todayStr = moment().format('MM_DD_YYYY');

  const response = await fetcherSol<
    { filename: string; query: string },
    DownloadCSVBeginGQLResponse
  >({
    query: gql`
      query BeginCSV($query: String!, $filename: String) {
        csv {
          begin(query: $query, filename: $filename) {
            id
            url
            status
          }
        }
      }
    `,
    variables: {
      query: payload,
      filename: `${filename}_${todayStr}`,
    },
  });

  if (response.csv.begin.status === 'error') {
    throw new Error();
  }

  if (response.csv.begin.status === 'processing') {
    response.csv.begin.url = '';
  }

  return response.csv.begin;
};

const useDownloadCSVStatus = (id?: string, pollingInterval = 3000) => {
  const canExecuteQuery = !!id;

  const { data, error, isLoading } = useSolQuery<DownloadCSVStatusGQLResponse>(
    {
      query:
        canExecuteQuery &&
        gql`
          query DownloadCSVStatus($id: String!) {
            csv {
              status(id: $id) {
                id
                status
                url
              }
            }
          }
        `,
      variables: {
        id,
      },
    },
    {
      refetchInterval: canExecuteQuery ? pollingInterval : 0,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
    },
  );

  const downloadStatus = data?.csv.status;

  if (downloadStatus?.status === 'error') {
    return {
      downloadStatus: undefined,
      error: new Error(),
      isLoading: false,
    };
  }

  return {
    downloadStatus,
    error,
    isLoading,
  };
};

const downloadFile = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.click();
  link.remove();
};

export const useDownloadCSV = ({ filename, query, variables }: DownloadCSVInput) => {
  const { pushNotification, removeAllNotifications } = useNotification();
  const [beginResponse, setBeginResponse] = useState<CSVJobStatus>();
  const [isLoading, setIsLoading] = useState(false);
  const [beginError, setBeginError] = useState<SolAPIError>();

  const { downloadStatus, error } = useDownloadCSVStatus(
    beginError ? undefined : beginResponse?.id,
  );

  const dispatchError = () => {
    pushNotification({
      type: 'error',
      message: t`Failed to download file. Please try again or contact support if the issue persists.`,
    });
  };

  useEffect(() => {
    // Reset begin response if download status finished processing (whether successful or not)
    if (downloadStatus?.status === 'completed') {
      setBeginResponse(undefined);
      setIsLoading(false);

      downloadFile(downloadStatus.url);

      pushNotification({
        type: 'success',
        message: t`Download complete!`,
      });
    }
  }, [downloadStatus]);

  useEffect(() => {
    if (error) {
      setBeginResponse(undefined);
      setIsLoading(false);
      dispatchError();
    }
  }, [error]);

  return {
    downloadStatus: downloadStatus ?? beginResponse,
    isLoading,
    error: error ?? beginError,
    beginDownload: async () => {
      removeAllNotifications();
      setBeginResponse(undefined);
      setBeginError(undefined);
      setIsLoading(true);

      try {
        const response = await fetchBeginDownloadCSV({ filename, query, variables });

        setBeginResponse(response);
        setBeginError(undefined);
      } catch (e) {
        dispatchError();
        setBeginResponse(undefined);
        setBeginError(e as SolAPIError);
        setIsLoading(false);
      }
    },
    cancelDownload: async () => {
      setBeginResponse(undefined);
      setBeginError(undefined);
      setIsLoading(false);
    },
  };
};

export const useTrafficVisitTrend = (
  startDate?: string,
  endDate?: string,
  {
    audienceId,
    vendorId,
    campaignIds = [],
  }: { audienceId?: string; vendorId?: string; campaignIds?: string[] } = {},
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSolQuery<TrafficVisitTrendGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query TrafficVisitTrend2(
          $startDate: DateTime!
          $endDate: DateTime!
          $adIds: [String]
          $audienceIds: [String]
        ) {
          visualization {
            trafficVisitTrend2(
              startDate: $startDate
              endDate: $endDate
              adIds: $adIds
              audienceIds: $audienceIds
            ) {
              sundayOfWeek
              allVisits: total
              botVisits: bot
              otherVisits: other
              targetVisits: target
              unresolvedVisits: unresolved
            }
          }
        }
      `,
    variables: {
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds: audienceId ? [audienceId] : [],
      adIds: campaignIds,
      vendorId,
    },
  });

  return {
    trafficTrendData: data?.visualization.trafficVisitTrend2,
    isLoading,
    error,
  };
};
