import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo, useState } from 'react';

import { CampaignActivityDataType } from '@/api/campaign';
import { TRAFFIC_TYPES } from '@/api/common';
import CampaignActivityTableActionMenu from '@/app/campaigns/CampaignActivityTableActionMenu';
import CampaignFilter from '@/app/campaigns/CampaignFilter';
import CampaignTableActionContent from '@/app/campaigns/CampaignTableActionContent';
import { Checkbox } from '@/components/Form';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType, FilterDropdown } from '@/components/Table';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { Link } from '@/components/typography';
import { CAMPAIGN_GROUP_MAX_LIMIT, CAMPAIGN_SCORE_MAX_LIMIT } from '@/constants';
import SolAPIError from '@/error/SolAPIError';
import { MetricSelector } from '@/features/MetricSelector';
import { VisitActivityLegend } from '@/features/VisitActivityLegend';
import { useTableState } from '@/providers/TableState';
import { ROUTES } from '@/router';

type Props = {
  data?: CampaignActivityDataType[];
  totalResults?: number;
  isLoading?: boolean;
  error: SolAPIError | null;
  onScoreCampaigns: (campaignIds: string[]) => void;
  onDisabledSeriesChange: (disabledSeriesType: TRAFFIC_TYPES[]) => void;
};

const CampaignActivityTable = ({
  data,
  totalResults,
  isLoading,
  error,
  onScoreCampaigns,
  onDisabledSeriesChange,
}: Props) => {
  const {
    pageFilters: { audience },
  } = usePageFilterContext();
  const { columns } = useTableState<CampaignActivityDataType>();
  const [selectedCampaigns, setSelectedCampaigns] = useState<CampaignActivityDataType[]>([]);
  const [metricSelectorOpen, setMetricSelectorOpen] = useState(false);

  const sortFields = ROUTES.campaigns.searchParams.sortBy.values;
  const isMaxCompareLimit =
    selectedCampaigns.length === Math.max(CAMPAIGN_GROUP_MAX_LIMIT, CAMPAIGN_SCORE_MAX_LIMIT);

  const handleSelectRow = (campaign: CampaignActivityDataType) => {
    setSelectedCampaigns((checkedCampaigns) => {
      const campaignIndex = checkedCampaigns.indexOf(campaign);
      const isCampaignSelected = campaignIndex >= 0;

      if (!isCampaignSelected && isMaxCompareLimit) {
        return checkedCampaigns;
      }

      if (isCampaignSelected) {
        return checkedCampaigns.filter((_, index) => index !== campaignIndex);
      }

      return [...checkedCampaigns, campaign];
    });
  };

  const handleRemoveSelectedCampaign = (campaign: CampaignActivityDataType) => {
    setSelectedCampaigns((checkedCampaigns) => checkedCampaigns.filter((c) => c !== campaign));
  };

  const allColumns: ColumnsType<CampaignActivityDataType> = useMemo(
    () => [
      {
        title: '',
        key: 'id',
        fixed: 'left',
        width: 40,
        render: (text, field) => {
          const isSelected = selectedCampaigns.some((campaign) => campaign.ad.id === field.ad.id);
          return <Checkbox checked={isSelected} isDisabled={!isSelected && isMaxCompareLimit} />;
        },
      },
      {
        title: t`Campaign`,
        key: 'campaignId',
        getSortKey: () => 'campaign.name',
        fixed: 'left',
        sorter: sortFields.includes('campaign.name'),
        width: 350,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <CampaignFilter />
          </FilterDropdown>
        ),
        render: (text, field) => (
          <Link variant="caption1" to={ROUTES.campaignById(field.ad.id)}>
            {field.ad.name}
          </Link>
        ),
      },
      ...columns,
    ],
    [columns, selectedCampaigns],
  );

  return (
    <Panel
      size="L"
      title={<Trans>Campaign Reporting</Trans>}
      actions={
        <CampaignActivityTableActionMenu onOpenMetricSelector={() => setMetricSelectorOpen(true)} />
      }
      verifyC99Tag
      isFullHeight
    >
      <VisitActivityLegend audience={audience} onDisabledSeriesChange={onDisabledSeriesChange} />
      <InstantSearchTable<CampaignActivityDataType>
        data-testid="campaign-table"
        columns={allColumns}
        dataSource={data}
        totalResults={totalResults}
        loading={isLoading}
        error={error}
        emptyMessage={<Trans>No campaigns found</Trans>}
        rowKey={(record) => record.ad.id}
        sticky
        scroll={{ x: 1200 }}
        actionContent={
          <CampaignTableActionContent
            selectedCampaigns={selectedCampaigns}
            onRemoveSelectedCampaign={handleRemoveSelectedCampaign}
            onScoreCampaigns={onScoreCampaigns}
          />
        }
        onRow={(record) => ({
          onClick: () => handleSelectRow(record),
        })}
      />
      <MetricSelector open={metricSelectorOpen} onClose={() => setMetricSelectorOpen(false)} />
    </Panel>
  );
};

export default CampaignActivityTable;
