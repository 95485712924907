import gql from 'graphql-tag';
import { useMemo } from 'react';

import { useDownloadCSV } from '@/api/common';
import { fetcherSol } from '@/api/sol-fetcher';
import { getVariablesFromTableParamsNew } from '@/api/util/getVariablesFromTableParams';
import { InstantSearchSortOrder, SolQueryParamsNew } from '@/components/InstantSearch';
import { useSolQuery } from '@/hooks/useSolQuery';

import {
  EventLogGQLResponse,
  EventLogParamType,
  EventLogType,
  EventLogTypeValues,
  EventLogTypesGQLResponse,
  NoteCreateGQLResponseType,
  NoteCreateVariablesType,
  NoteDeleteGQLResponseType,
  NoteUpdateGQLResponseType,
  NoteUpdateVariablesType,
} from './eventLogType';
import { hydrateEventLogMessage } from './eventLogUtil';

export const useEventLogCSVDownload = (
  tableParams?: SolQueryParamsNew,
  startDate?: string,
  endDate?: string,
) => {
  return useDownloadCSV({
    filename: 'C99_eventlog',
    query: gql`
      query AuditLogs(
        $page: Pagination!
        $sort: [SortParamInput]!
        $filter: [FilterParamInput]!
        $startDate: DateTime!
        $endDate: DateTime!
        $searchQuery: String
      ) {
        auditLogs {
          get(
            page: $page
            sort: $sort
            filter: $filter
            startDate: $startDate
            endDate: $endDate
            searchQuery: $searchQuery
          ) {
            totalEdges
            edges {
              node {
                Details: detailsString
                Source: sourceString
                Type: typeString
                Date: occurredAt
              }
            }
          }
        }
      }
    `,
    variables: {
      ...getVariablesFromTableParamsNew(tableParams),
      page: {
        offset: 0,
        limit: 1000000,
      },
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });
};

export const useEventLog = (
  tableParams?: SolQueryParamsNew,
  startDate?: string,
  endDate?: string,
) => {
  const canExecuteQuery = !!(tableParams && startDate?.length && endDate?.length);

  const { data, error, isLoading, mutate } = useSolQuery<EventLogGQLResponse>({
    query:
      canExecuteQuery &&
      gql`
        query AuditLogs(
          $page: Pagination!
          $sort: [SortParamInput]!
          $filter: [FilterParamInput]!
          $startDate: DateTime!
          $endDate: DateTime!
          $searchQuery: String
        ) {
          auditLogs {
            get(
              page: $page
              sort: $sort
              filter: $filter
              startDate: $startDate
              endDate: $endDate
              searchQuery: $searchQuery
            ) {
              totalEdges
              edges {
                node {
                  id
                  detailsFormat
                  eventData
                  occurredAt
                  sourceFormat
                  typeFormat
                }
              }
            }
          }
        }
      `,
    variables: {
      ...getVariablesFromTableParamsNew(tableParams),
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  const eventLog = useMemo<EventLogType[] | undefined>(() => {
    if (data) {
      return data.auditLogs.get.edges.map(({ node }) => {
        const params = JSON.parse(node.eventData) as Record<string, EventLogParamType>;

        return {
          id: node.id,
          date: node.occurredAt,
          rawDetails: hydrateEventLogMessage(node.detailsFormat, params, false) as
            | string
            | number
            | boolean,
          details: hydrateEventLogMessage(node.detailsFormat, params),
          source: hydrateEventLogMessage(node.sourceFormat, params),
          type: node.typeFormat as EventLogTypeValues,
        };
      });
    }

    return data;
  }, [data]);

  return {
    eventLog,
    totalResults: data?.auditLogs.get.totalEdges,
    isLoading,
    error,
    mutate,
  };
};

export const useEventLogTypes = (startDate?: string, endDate?: string) => {
  const canExecuteQuery = !!(startDate?.length && endDate?.length);

  const { data, error, isLoading } = useSolQuery<EventLogTypesGQLResponse>({
    query:
      canExecuteQuery &&
      gql`
        query getAuditLogTypes(
          $page: Pagination!
          $sort: [SortParamInput]!
          $filter: [FilterParamInput]!
          $startDate: DateTime!
          $endDate: DateTime!
        ) {
          auditLogs {
            getTypes(
              page: $page
              sort: $sort
              filter: $filter
              startDate: $startDate
              endDate: $endDate
            ) {
              edges {
                node {
                  id
                  name: typeString
                }
              }
            }
          }
        }
      `,
    variables: {
      page: {
        offset: 0,
        limit: 1000,
      },
      sort: {
        field: 'typeString',
        direction: InstantSearchSortOrder.ASC,
      },
      filter: [],
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  return {
    eventTypes: data?.auditLogs.getTypes.edges.map(({ node }) => node),
    isLoading,
    error,
  };
};

export const mutateNoteCreate = async (note: string, occurredAt: string) => {
  const newNote = await fetcherSol<NoteCreateVariablesType, NoteCreateGQLResponseType>({
    query: gql`
      mutation CreateNote($note: CreateAuditLogNoteInput!) {
        auditLogs {
          createNote(note: $note) {
            id
            detailsFormat
            eventData
            occurredAt
            sourceFormat
            typeFormat
          }
        }
      }
    `,
    variables: {
      note: {
        note,
        occurredAt,
      },
    },
  });

  return newNote.auditLogs.createNote;
};

export const mutateNoteUpdate = async (id: string, note: string, occurredAt: string) => {
  const updatedNote = await fetcherSol<NoteUpdateVariablesType, NoteUpdateGQLResponseType>({
    query: gql`
      mutation UpdateNote($note: UpdateAuditLogNoteInput!) {
        auditLogs {
          updateNote(note: $note) {
            id
            detailsFormat
            eventData
            occurredAt
            sourceFormat
            typeFormat
          }
        }
      }
    `,
    variables: {
      note: {
        id,
        note,
        occurredAt,
      },
    },
  });

  return updatedNote.auditLogs.updateNote;
};

export const mutateNoteDelete = async (id: string) => {
  const deletedNote = await fetcherSol<{ deleteNoteId: string }, NoteDeleteGQLResponseType>({
    query: gql`
      mutation DeleteNote($deleteNoteId: String!) {
        auditLogs {
          deleteNote(id: $deleteNoteId) {
            success
          }
        }
      }
    `,
    variables: {
      deleteNoteId: id,
    },
  });

  return deletedNote.auditLogs.deleteNote.success;
};
