import gql from 'graphql-tag';

import { useSolImmutableQuery, useSolQuery } from '@/hooks/useSolQuery';

import {
  ChannelListGQLResponse,
  ChannelTrafficOverviewKPIsGQLResponse,
  ChannelVisitsOverviewGQLResponse,
  ChannelWeeklyVisitsGQLResponse,
  ChannelsTrafficBreakdownGQLResponse,
} from './channelType';

const useBaseChannelTrafficOverviewKPIs = (
  canExecuteQuery: boolean,
  channelId?: string | null,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const { data, isLoading, error } = useSolQuery<ChannelTrafficOverviewKPIsGQLResponse>({
    query:
      canExecuteQuery &&
      gql`
        query ChannelTrafficOverviewKpis(
          $startDate: DateTime
          $endDate: DateTime
          $channelId: String
          $audienceId: String
        ) {
          visualization {
            trafficOverviewKpis(
              startDate: $startDate
              endDate: $endDate
              channelId: $channelId
              audienceId: $audienceId
            ) {
              audienceVisits
              spend
              spendPerAudienceVisit
              spendPerTamVisit
              spendPerVisit
              tamVisits
              visits
            }
          }
        }
      `,
    variables: {
      channelId,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  return {
    channelTrafficOverviewKPIs: data?.visualization.trafficOverviewKpis,
    isLoading,
    error,
  };
};

export const useChannelOverviewTrafficOverviewKPIs = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = !!(startDate?.length && endDate?.length);
  return useBaseChannelTrafficOverviewKPIs(canExecuteQuery, null, startDate, endDate, audienceId);
};

export const useChannelTrafficOverviewKPIs = (
  channelId?: string,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = !!(channelId && startDate?.length && endDate?.length);
  return useBaseChannelTrafficOverviewKPIs(
    canExecuteQuery,
    channelId,
    startDate,
    endDate,
    audienceId,
  );
};

export const useChannelsTrafficBreakdown = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = !!(startDate?.length && endDate?.length);
  const { data, isLoading, error } = useSolQuery<ChannelsTrafficBreakdownGQLResponse>({
    query:
      canExecuteQuery &&
      gql`
        query TrafficByChannel($startDate: DateTime, $endDate: DateTime, $audienceId: String) {
          visualization {
            trafficByChannel(startDate: $startDate, endDate: $endDate, audienceId: $audienceId) {
              channel {
                id
                name
              }
              cost
              spendPerVisit {
                all
                audience
                tam
              }
              visits {
                all
                audience
                tam
              }
            }
          }
        }
      `,
    variables: {
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  return {
    channelsTrafficBreakdown: data?.visualization.trafficByChannel,
    isLoading,
    error,
  };
};

export const useChannelWeeklyVisits = (
  channelId?: string,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = channelId && startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSolQuery<ChannelWeeklyVisitsGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query ChannelWeeklyTraffic(
          $startDate: DateTime
          $endDate: DateTime
          $channelId: String
          $audienceId: String
        ) {
          visualization {
            weeklyTraffic(
              startDate: $startDate
              endDate: $endDate
              channelId: $channelId
              audienceId: $audienceId
            ) {
              vendor {
                id
                name
                color
              }
              data {
                cost
                spendPerVisit {
                  all
                  audience
                  tam
                }
                sundayOfWeek
                visits {
                  all
                  audience
                  tam
                }
              }
            }
          }
        }
      `,
    variables: {
      channelId,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  return {
    channelWeeklyVisits: data?.visualization.weeklyTraffic,
    isLoading,
    error,
  };
};

export const useChannelVisitsOverview = (
  channelId?: string,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = channelId && startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSolQuery<ChannelVisitsOverviewGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query ChannelVisitsOverview(
          $startDate: DateTime
          $endDate: DateTime
          $channelId: String
          $audienceId: String
        ) {
          visualization {
            trafficByVendor(
              startDate: $startDate
              endDate: $endDate
              channelId: $channelId
              audienceId: $audienceId
            ) {
              totals {
                spendPerVisit {
                  all
                  audience
                  tam
                }
                visits {
                  all
                  audience
                  tam
                }
              }
              data {
                spendPerVisit {
                  all
                  tam
                }
                vendor {
                  id
                  name
                }
                visits {
                  all
                  audience
                  tam
                }
              }
            }
          }
        }
      `,
    variables: {
      channelId,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceId,
    },
  });

  return {
    channelVisitsData: data?.visualization.trafficByVendor.data,
    channelVisitsSummary: data?.visualization.trafficByVendor.totals,
    isLoading,
    error,
  };
};

export const useChannelList = () => {
  const { data, error, isLoading } = useSolImmutableQuery<ChannelListGQLResponse>({
    query: gql`
      query GetChannelsList {
        channels {
          getAll {
            id
            name
          }
        }
      }
    `,
  });

  const channels = data?.channels.getAll.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  return {
    channels,
    error,
    isLoading,
  };
};

export const useChannelById = (channelId?: string) => {
  const { channels, isLoading, error } = useChannelList();
  const channel = channels?.find((item) => item.id === channelId);

  return {
    channel,
    error,
    isLoading,
  };
};
