import { Point, PointerEventObject } from 'highcharts';
import { useEffect } from 'react';

import { FlareChart } from '@/components/Flare';
import { getNearestPointFromChartPointerEvent } from '@/helper/highcharts-util';

const useHighchartsHover = (
  chart?: FlareChart,
  onHoverChart?: (event: PointerEventObject, point: Point) => void,
) => {
  useEffect(() => {
    if (onHoverChart && chart) {
      const handleHover = (event: MouseEvent | TouchEvent) => {
        if (chart) {
          const chartEvent = chart.pointer.normalize(event);
          const nearestPoint = getNearestPointFromChartPointerEvent(chart, chartEvent);
          if (nearestPoint) {
            onHoverChart(chartEvent, nearestPoint);
          }
        }
      };

      if (chart?.container) {
        chart.container.addEventListener('mousemove', handleHover);
        chart.container.addEventListener('touchmove', handleHover);
        chart.container.addEventListener('touchstart', handleHover);
      }

      return () => {
        if (chart?.container) {
          chart.container.removeEventListener('mousemove', handleHover);
          chart.container.removeEventListener('touchmove', handleHover);
          chart.container.removeEventListener('touchstart', handleHover);
        }
      };
    }
  }, [chart, onHoverChart]);
};

export default useHighchartsHover;
