import { t } from '@lingui/core/macro';
import { Point, PointerEventObject } from 'highcharts';
import moment from 'moment';
import { useMemo } from 'react';

import { OpportunityType, useOpportunityNonDigitalEventsTrends } from '@/api/opportunity';
import ClosedWonNonDigitalEventsLoader from '@/app/closed-won/ClosedWonNonDigitalEventsLoader';
import TimelineChartTooltipEvents from '@/app/closed-won/TimelineChartTooltipEvents';
import Flare, {
  Axis,
  Bubble,
  ChartCallbackFunction,
  FlareSeriesOptions,
  Tooltip,
} from '@/components/Flare';
import { Panel } from '@/components/panels';
import { DateFormatMD } from '@/constants';
import { getUTCTime } from '@/helper/dateHelper';
import {
  COLOR_AQUA_300,
  COLOR_ORANGE_400,
  COLOR_PINK_400,
  COLOR_TEAL_400,
  COLOR_VIOLET_400,
  COLOR_YELLOW_300,
} from '@/styles/palette';

import styles from './ClosedWonNonDigitalEventsChart.module.scss';

const COLORS = [
  COLOR_VIOLET_400,
  COLOR_ORANGE_400,
  COLOR_TEAL_400,
  COLOR_YELLOW_300,
  COLOR_PINK_400,
  COLOR_AQUA_300,
];

type Props = {
  opportunity?: OpportunityType;
  marginLeft: number;
  marginRight: number;
  onHoverChart?: (event: PointerEventObject, point: Point) => void;
  onHoverLeaveChart?: (event: MouseEvent) => void;
  onPostRenderChart?: ChartCallbackFunction;
};

type CustomPointData = {
  events: {
    id: string;
    date: string;
  }[];
  seriesName: string;
  seriesId: string;
};

const ClosedWonNonDigitalEventsChart = ({
  opportunity,
  marginLeft,
  marginRight,
  onHoverChart,
  onHoverLeaveChart,
  onPostRenderChart,
}: Props) => {
  const { data, isLoading, error } = useOpportunityNonDigitalEventsTrends(opportunity?.id);

  const chartData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (data == null) {
      return undefined;
    }

    return data.map((series, seriesIndex) => {
      return {
        id: series.vendor.id,
        name: `${series.channel.name}: ${series.vendor.name}`,
        data: series.data
          .filter((d) => d.events.length)
          .map((d) => ({
            date: d.sundayOfWeek,
            category: seriesIndex,
            radius: d.events.length > 1 ? 10 : 5,
            custom: {
              events: d.events,
              seriesName: series.vendor.name,
              seriesId: series.vendor.id,
            } as CustomPointData,
          })),
      };
    });
  }, [data]);

  // TODO: Rely on the sundayOfWeek dates from the data instead of the opportunity dates
  const opportunityStartDate = opportunity?.openDate
    ? +moment(opportunity.openDate).utc().subtract(90, 'd').startOf('week').startOf('day')
    : undefined;
  const opportunityCloseDate = opportunity?.closeDate
    ? +moment(opportunity?.closeDate).utc().endOf('day')
    : undefined;

  return (
    <Panel title={t`Non-Digital Events`} size="L" verifyC99Tag style={{ height: 302 }} noPadding>
      <Flare
        key={opportunity?.id}
        className={styles.chart}
        data={chartData}
        parseX={getUTCTime}
        description={t`A timeline chart which shows the dates of all non-digital events across channels`}
        isLoading={isLoading}
        error={error}
        chartMarginLeft={marginLeft + 8}
        chartMarginRight={marginRight + 8}
        postRender={onPostRenderChart}
        colors={COLORS}
        onHoverChart={onHoverChart}
        onHoverLeaveChart={onHoverLeaveChart}
      >
        <Bubble
          x="date"
          y="category"
          z="radius"
          sizeBy="width"
          minSize={10}
          maxSize={20}
          loader={<ClosedWonNonDigitalEventsLoader />}
        />
        <Axis
          type="category"
          categories={chartData?.map((d) => d.name)}
          tickmarkPlacement="on"
          tickPositions={chartData?.map((_, i) => i)}
          gridlineWidth={8}
          opposite
          reversed
          useHTML
          labelsNoWrap
        />
        <Axis
          type="datetime"
          position="bottom"
          crosshair="line"
          labelFormat={(item) => moment(item.value).format(DateFormatMD)}
          baselineWidth={0}
          gridlineWidth={0}
          tickLength={0}
          labelDistance={0}
          min={opportunityStartDate}
          max={opportunityCloseDate}
        />
        <Tooltip
          titleFormat={(point) => {
            const startDate = moment(point?.x);
            let endDate = moment(point?.x).add(6, 'd').endOf('day');
            if (opportunityCloseDate && endDate.isAfter(opportunityCloseDate)) {
              endDate = moment(opportunityCloseDate);
            }
            return `${point.custom?.seriesName}: ${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`;
          }}
          rowContent={(points) => (
            <TimelineChartTooltipEvents
              events={(points[0].custom as CustomPointData).events}
              name={(points[0].custom as CustomPointData).seriesName}
            />
          )}
        />
      </Flare>
    </Panel>
  );
};

export default ClosedWonNonDigitalEventsChart;
