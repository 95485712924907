import { t } from '@lingui/core/macro';
import { InputRef } from 'antd';
import { useEffect, useRef } from 'react';

import { ChatMessageType } from '@/api/ai';
import Drawer from '@/components/Drawer';
import { Flex } from '@/components/Flex';
import { AISearchInput } from '@/components/Form';
import LoadingIndicator from '@/components/LoadingIndicator';

import styles from './AIDrawer.module.scss';
import AIMessage from './AIMessage';

type Props = {
  open: boolean;
  messages: ChatMessageType[];
  isAIResponseLoading?: boolean;
  isUserChatDisabled?: boolean;
  onClose: () => void;
  onSendMessage: (message: string) => void;
};

const AIDrawer = ({
  open,
  messages,
  isAIResponseLoading = false,
  isUserChatDisabled = false,
  onClose,
  onSendMessage,
}: Props) => {
  const inputRef = useRef<InputRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollDown = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 0);
  };

  const handleSearchMessage = (value: string) => {
    onSendMessage(value);
    scrollDown();
  };

  useEffect(() => {
    if (open) {
      scrollDown();
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [open]);

  return (
    <Drawer title={t`C99 AI Search`} open={open} width={676} onClose={onClose}>
      <Flex vertical justify="space-between" gap={16} fullHeight>
        <Flex ref={containerRef} className={styles.content} vertical gap="medium">
          {messages.map((message, index) => (
            <AIMessage type={message.role} key={index}>
              {message.content}
            </AIMessage>
          ))}
          {isAIResponseLoading && (
            <AIMessage type="loading">
              <LoadingIndicator />
            </AIMessage>
          )}
        </Flex>
        <AISearchInput
          ref={inputRef}
          size="large"
          showSearchButton
          isSearchDisabled={isUserChatDisabled}
          onSearch={handleSearchMessage}
        />
      </Flex>
    </Drawer>
  );
};

export default AIDrawer;
