import Highcharts, { Options } from 'highcharts';

import { FlareChart, FlareProps } from '../types';

const initializeOptions = (
  defaultOptions: Options | undefined,
  {
    title,
    subtitle,
    description,
    colors,
    inverted,
    chartMarginTop,
    chartMarginBottom,
    chartMarginLeft,
    chartMarginRight,
    postRender,
  }: Partial<FlareProps>,
): Highcharts.Options => {
  return {
    ...defaultOptions,
    colors,
    chart: {
      ...defaultOptions?.chart,
      backgroundColor: 'transparent',
      events: {
        render: postRender && ((event) => postRender?.(event.target as unknown as FlareChart)),
      },
      style: {
        ...defaultOptions?.chart?.style,
        fontFamily: '"Inter", sans-serif',
      },
      spacingLeft: 32,
      spacingRight: 32,
      spacingTop: 24,
      spacingBottom: 20,
      marginTop: chartMarginTop,
      marginBottom: chartMarginBottom,
      marginLeft: chartMarginLeft,
      marginRight: chartMarginRight,
      inverted,
    },
    title: {
      ...defaultOptions?.title,
      text: title,
      style: {
        ...defaultOptions?.title?.style,
        fontSize: '16px',
      },
    },
    subtitle: {
      ...defaultOptions?.subtitle,
      text: subtitle,
    },
    accessibility: {
      ...defaultOptions?.accessibility,
      description,
    },
    plotOptions: {
      ...defaultOptions?.plotOptions,
      series: {
        ...defaultOptions?.plotOptions?.series,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };
};

export default initializeOptions;
