import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import moment from 'moment';
import { useMemo } from 'react';

import { CompanyDetailType } from '@/api/activity';
import { SolError } from '@/api/solType';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType } from '@/components/Table';
import { Link } from '@/components/typography';
import { USDateViewFormat } from '@/constants/formats';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  data?: CompanyDetailType[];
  totalResults?: number;
  isLoading?: boolean;
  error: SolError | Error | null;
};

const AudienceMembershipListTable = ({ data, totalResults, isLoading = false, error }: Props) => {
  const sortFields = ROUTES.audienceDetail.searchParams.sortBy.values;

  const columns: ColumnsType<CompanyDetailType> = useMemo(
    () => [
      {
        title: t`Company Name`,
        key: 'name',
        fixed: 'left',
        sorter: sortFields.indexOf('name') >= 0,
        render: (text, field) =>
          field.tld && !!field.metrics?.visits && !!field.metrics.lastActivityDate ? (
            <Link variant="caption1" to={`${ROUTES.webTrafficActivity.path}?search=${field.tld}`}>
              {text}
            </Link>
          ) : (
            text
          ),
      },
      {
        title: t`Domain`,
        key: 'tld',
      },
      {
        title: t`Visits (Last 30d)`,
        key: 'metrics.visits',
        align: 'right',
        sorter: sortFields.indexOf('metrics.visits') >= 0,
        render: (text, field) =>
          field.metrics?.visits === 0 ? '' : numberFormat(field.metrics?.visits),
      },
      {
        title: t`Last activity date`,
        key: 'metrics.lastActivityDate',
        align: 'right',
        sorter: sortFields.indexOf('metrics.lastActivityDate') >= 0,
        render: (text, field) =>
          field.metrics?.lastActivityDate
            ? moment(field.metrics.lastActivityDate).format(USDateViewFormat)
            : '',
      },
    ],
    [sortFields],
  );

  return (
    <InstantSearchTable<CompanyDetailType>
      columns={columns}
      dataSource={data}
      totalResults={totalResults}
      loading={isLoading}
      emptyMessage={<Trans>No audience membership found</Trans>}
      error={error}
      rowKey={(record) => record.tld}
    />
  );
};

export default AudienceMembershipListTable;
