import * as Sentry from '@sentry/react';
import { StytchB2BHeadlessClient } from '@stytch/vanilla-js/dist/b2b/index.headless';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosResponse,
  CanceledError,
  Method,
  isAxiosError,
} from 'axios';

import { UserProfileType } from '@/api/user';
import SolAPIError from '@/error/SolAPIError';
import getSolStandardError from '@/error/sol-standard-errors';

interface IConfig {
  baseURL?: string;
  timeout?: number;
}

interface IRequest<TData> {
  url: string;
  baseURL?: string;
  method?: Method;
  payload?: unknown;
  params?: unknown;
  timeout?: number;
  data?: TData;
  paramsSerializer?: AxiosRequestConfig['paramsSerializer'];
  headers?: { [key: string]: string | number | undefined | null };
  isNotificationError?: boolean;
  signal?: AbortSignal;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

const DEFAULT_REQUEST_TIMEOUT = 60 * 1000;

const API_HOST =
  process.env.NODE_ENV === 'development' ? (window as Window).API_HOST : document.location.origin;

// localhost calls to `sol` go to http://localhost:3000/sol/* and we rewrite the path in Vite's
// proxy to route to the proper url. For deployed environments, we go directly to Sol's host.
export const SOL_API_HOST =
  window.ENV === 'local' ? `${API_HOST}/sol/` : import.meta.env.VITE_SOL_HOST;

class Fetcher {
  private instance: AxiosInstance;

  private userData?: UserProfileType = undefined;
  private stytch?: StytchB2BHeadlessClient;
  private logOut?: () => void;

  constructor({ baseURL = '', timeout = DEFAULT_REQUEST_TIMEOUT }: IConfig) {
    this.instance = axios.create({
      baseURL,
      timeout,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.instance.interceptors.request.use(async (config) => {
      const jwtToken = this?.stytch?.session.getTokens()?.session_jwt;

      if (!jwtToken) {
        return config;
      }

      const headers = {
        ...config.headers,
        Authorization: `Bearer ${jwtToken}`,
        'x-instance-id': this?.userData?.currentOrg.instanceId,
      };

      return { ...config, headers };
    });

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      },
    );
  }

  handlerCatch = <TResponse>(e: AxiosError | CanceledError<TResponse | unknown>) => {
    if (e instanceof CanceledError) {
      throw e;
    }

    if (!e.response) {
      console.error(e);
      throw e;
    }

    if (e.response?.status === 401) {
      this.logOut?.();
    }

    if (isAxiosError(e) && e.response?.data) {
      const solError = getSolStandardError(e);
      const requestId = e.response.headers['x-request-id'];
      const error = new SolAPIError(requestId, [solError]);
      console.error(error.toString());
      throw error;
    }

    throw e;
  };

  private request = <TData = undefined, TResponse = unknown>(
    url: string,
    {
      method = 'GET',
      params,
      signal,
      headers,
      baseURL,
      data,
      timeout,
      paramsSerializer,
      onUploadProgress,
    }: Omit<IRequest<TData>, 'url'>,
  ): Promise<AxiosResponse<TResponse>> => {
    return this.instance
      .request({
        url,
        method,
        params,
        signal,
        headers,
        baseURL,
        data,
        timeout,
        paramsSerializer,
        onUploadProgress,
      })
      .then((resp) => resp)
      .catch((e: AxiosError) => this.handlerCatch<TResponse>(e));
  };

  setUserData = (
    userData: UserProfileType | undefined,
    stytch: StytchB2BHeadlessClient,
    logOut: () => void,
  ) => {
    this.userData = userData;
    this.stytch = stytch;
    this.logOut = logOut;
  };

  requestSol = <TData = undefined, TResponse = unknown>({
    url,
    ...rest
  }: IRequest<TData>): Promise<AxiosResponse<TResponse>> => {
    if (!url.endsWith('/') && !url.includes('?')) {
      url += '/';
    }

    return this.request(`${SOL_API_HOST}${url}`, rest);
  };
}

export default new Fetcher({});
