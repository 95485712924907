import gql from 'graphql-tag';
import { useMemo } from 'react';

import { useDownloadCSV } from '@/api/common';
import { SolQueryParamsNew } from '@/components/InstantSearch';
import { useSolQuery } from '@/hooks/useSolQuery';

import { getVariablesFromTableParamsNew } from '../util/getVariablesFromTableParams';
import {
  CompanyTrendVisitsGQLResponse,
  WebTrafficActivityDetailSummaryGQLResponse,
  WebTrafficActivityListGQLResponse,
} from './activityType';

const convertInTamOperator = (tableParams?: SolQueryParamsNew) => {
  if (tableParams?.filter != null) {
    const inTamFilter = tableParams.filter.find((filter) => filter.field === 'inTam');
    if (inTamFilter) {
      inTamFilter.operator = 'eq';
    }
  }
  return tableParams;
};

export const useWebTrafficActivityListCSVDownload = (
  tableParams?: SolQueryParamsNew,
  startDate?: string,
  endDate?: string,
) => {
  const newTableParams = convertInTamOperator(tableParams);

  return useDownloadCSV({
    filename: 'C99_companyactivity',
    query: gql`
      query WebTrafficCompanyActivityList(
        $page: Pagination!
        $sort: [SortParamInput]!
        $filter: [FilterParamInput]!
        $startDate: DateTime!
        $endDate: DateTime!
        $searchQuery: String
      ) {
        visualization {
          companyActivity(
            page: $page
            sort: $sort
            filter: $filter
            startDate: $startDate
            endDate: $endDate
            searchQuery: $searchQuery
          ) {
            edges {
              node {
                Company: name
                Domain: tld
                inTam
                Region: region {
                  Name: name
                }
                Revenue_Range: revRange {
                  Name: name
                }
                Industry: sector {
                  Name: name
                }
                Metrics: metrics {
                  Impressions: impressions
                  Visits: visits
                  Last_Visit: lastActivityDate
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      ...getVariablesFromTableParamsNew(newTableParams),
      page: {
        offset: 0,
        limit: 1000000,
      },
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });
};

export const useWebTrafficActivityList = (
  tableParams?: SolQueryParamsNew,
  startDate?: string,
  endDate?: string,
) => {
  const canExecuteQuery = tableParams && startDate?.length && endDate?.length;

  const newTableParams = convertInTamOperator(tableParams);

  const { data, error, isLoading } = useSolQuery<WebTrafficActivityListGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query WebTrafficCompanyActivityList(
          $page: Pagination!
          $sort: [SortParamInput]!
          $filter: [FilterParamInput]!
          $queryId: String
          $startDate: DateTime!
          $endDate: DateTime!
          $searchQuery: String
        ) {
          visualization {
            companyActivity(
              page: $page
              sort: $sort
              filter: $filter
              queryId: $queryId
              startDate: $startDate
              endDate: $endDate
              searchQuery: $searchQuery
            ) {
              edges {
                node {
                  id
                  name
                  tld
                  inTam
                  metrics {
                    impressions
                    lastActivityDate
                    visits
                  }
                  region {
                    id
                    name
                  }
                  revRange {
                    id
                    name
                  }
                  sector {
                    id
                    name
                  }
                  audiences {
                    id
                    name
                  }
                }
              }
              queryId
              totalEdges
            }
          }
        }
      `,
    variables: {
      ...getVariablesFromTableParamsNew(newTableParams),
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  return {
    companies: data?.visualization.companyActivity.edges.map((edge) => edge.node),
    totalResults: data?.visualization.companyActivity.totalEdges,
    queryId: data?.visualization.companyActivity.queryId,
    isLoading,
    error,
  };
};

export const useCompanyTrendVisitsById = (
  companyId?: string,
  startDate?: string,
  endDate?: string,
) => {
  const canExecuteQuery = companyId && startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSolQuery<CompanyTrendVisitsGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query CompanyVisitTrend($startDate: DateTime!, $endDate: DateTime!, $companyId: String!) {
          visualization {
            companyVisitTrend(startDate: $startDate, endDate: $endDate, companyId: $companyId) {
              sundayOfWeek
              visits
            }
          }
        }
      `,
    variables: {
      companyId,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  return {
    companyTrendVisits: data?.visualization.companyVisitTrend,
    isLoading,
    error,
  };
};

export const useCompanySummaryById = (companyId?: string, startDate?: string, endDate?: string) => {
  const canExecuteQuery = companyId && startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSolQuery<WebTrafficActivityDetailSummaryGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query CompanyVisitSummary(
          $startDate: DateTime!
          $endDate: DateTime!
          $groupingField: TrafficVisitBreakdownGrouping!
          $companyId: String!
        ) {
          visualization {
            trafficVisitBreakdown(
              startDate: $startDate
              endDate: $endDate
              groupingField: $groupingField
              companyId: $companyId
            ) {
              count
              percentOfTotal
              total
              vendor {
                channel {
                  id
                  name
                  color
                }
                id
                name
              }
            }
            companySummary(startDate: $startDate, endDate: $endDate, companyId: $companyId) {
              audiences {
                id
                name
              }
              opportunities {
                id
                name
                isRecentDeal
              }
              metrics {
                lastActivityDate
              }
              webActivityPages
            }
          }
        }
      `,
    variables: {
      companyId,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      groupingField: 'vendor',
    },
  });

  const dataWithVendorDisplayName = useMemo(() => {
    if (!data) {
      return data;
    }

    const updatedTrafficVisitBreakdown = data.visualization.trafficVisitBreakdown.map((d) => {
      const displayName = d.vendor.channel?.name
        ? `${d.vendor.name} - ${d.vendor.channel.name}`
        : d.vendor.name;

      return {
        ...d,
        vendor: {
          ...d.vendor,
          displayName,
        },
      };
    });

    return {
      companySummary: data?.visualization.companySummary,
      trafficVisitBreakdown: updatedTrafficVisitBreakdown.sort((a, b) => b.count - a.count),
    };
  }, [data]);

  return {
    companySummary: dataWithVendorDisplayName,
    isLoading,
    error,
  };
};
