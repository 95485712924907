import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';

import { useROMSByCampaignId } from '@/api/campaign';
import FlareBenchmarkLine from '@/app/campaigns/[campaigns]/FlareBenchmarkLine';
import Flare, { Axis, Column, Legend, Tooltip } from '@/components/Flare';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { AUDIENCE_PRIMARY, TAM_PRIMARY, VISITS_PRIMARY } from '@/constants';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  campaignName?: string;
  campaignIds?: string[];
  isLoading?: boolean;
};

const CampaignReturnOnMarketingSpendChart = ({
  campaignName,
  campaignIds,
  isLoading: isCampaignLoading,
}: Props) => {
  const {
    pageFilters: { start_date: startDate, end_date: endDate, audience },
  } = usePageFilterContext();

  const {
    romsData,
    isLoading: isROMSLoading,
    error,
  } = useROMSByCampaignId(campaignIds, startDate, endDate, audience?.id);

  const isLoading = isCampaignLoading || isROMSLoading;

  const data = useMemo(() => {
    if (!romsData) {
      return undefined;
    }

    return [
      {
        id: 'roms',
        name: t`Return On Marketing Spend`,
        data: [
          {
            id: 'industryROMS',
            amount: romsData.industryAverageRoms,
            name: t`Industry Channel Benchmark`,
          },
          { id: 'channelROMS', amount: romsData.channelAverageRoms, name: t`Your Channel Average` },
          { id: 'campaignROMS', amount: romsData.roms, name: campaignName || '' },
        ],
      },
    ];
  }, [romsData]);

  return (
    <Panel
      title={<Trans>Return On Marketing Spend (ROMS)</Trans>}
      infoPopover={{
        title: t`ROMS`,
        body: t`Rolling 2 week average of your Return On Marketing Spend compared to all other campaigns within the same channel and channel industry averages.`,
      }}
      style={{ height: 465 }}
      noPadding
    >
      <Flare
        data={data}
        description={t`A Return On Marketing Spend (ROMS) bar chart showing industry channel ROMS benchmark, channel ROMS benchmark, and your campaign's ROMS`}
        isLoading={isLoading}
        error={error}
        colors={[VISITS_PRIMARY, TAM_PRIMARY, AUDIENCE_PRIMARY]}
      >
        <Legend noToggleVisibility showByCategory />
        <Column
          y="amount"
          position="overlap"
          colorByPoint
          showDataLabels
          groupPadding={0.15}
          dataLabelFormat={(label) => numberFormat(label.y, { precision: 1, isMultiplier: true })}
          skeletonLoaderOptions={{ numCategories: 3, numSeries: 1 }}
        />
        <Axis
          position="left"
          labelFormat={(item) => numberFormat(item.value, { precision: 0, isMultiplier: true })}
        />
        <Axis position="bottom" categories={data?.[0]?.data.map((d) => d.name)} crosshair="rect" />
        <Tooltip
          titleFormat={() => data?.[0].name}
          rowLabelFormat={(point) => point?.name}
          rowValueFormat={(item) => numberFormat(item?.y, { precision: 1, isMultiplier: true })}
        />
        <FlareBenchmarkLine index={0} />
        <FlareBenchmarkLine index={1} />
      </Flare>
    </Panel>
  );
};

export default CampaignReturnOnMarketingSpendChart;
