import { ReactNode } from 'react';

import InfoCircle from '@/assets/svg/bx-info-circle.svg?react';
import Popover from '@/components/Popover';
import { ActionIcon } from '@/components/buttons';
import { Text } from '@/components/typography';

import styles from './InfoPopover.module.scss';

type Props = {
  title: ReactNode;
  body: ReactNode;
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu';
};

const InfoPopover = ({ title, body, trigger = 'click' }: Props) => {
  return (
    <Popover
      variant="dark"
      classNames={{ root: styles.overlay }}
      content={
        <div className={styles.overlayContent}>
          <Text variant="caption1" color="light-green">
            {title}
          </Text>
          <Text variant="body2" color="white">
            {body}
          </Text>
        </div>
      }
      trigger={trigger}
      placement="right"
    >
      <ActionIcon className={styles.icon} color="black" icon={<InfoCircle />} size="small" />
    </Popover>
  );
};

export default InfoPopover;
