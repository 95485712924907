import { Chart, PointerEventObject } from 'highcharts';

export const getNearestPointFromChartPointerEvent = (chart: Chart, event: PointerEventObject) => {
  const chartLeft = chart.plotLeft;

  const allClosestPoints = chart.series.map((series) => series.searchPoint(event, true));
  const nearestPoint = allClosestPoints.reduce((memo, point) => {
    if (!memo?.plotX) {
      return point;
    }

    if (!point?.plotX) {
      return memo;
    }

    if (
      Math.abs(event.chartX - chartLeft - memo.plotX) >
      Math.abs(event.chartX - chartLeft - point.plotX)
    ) {
      return point;
    } else {
      return memo;
    }
  }, undefined);

  return nearestPoint;
};

export const getNearestPointFromXValue = (chart: Chart, xValue: number) => {
  for (const series of chart.series) {
    for (const point of series.points) {
      if (point.x === xValue) {
        return point;
      }
    }
  }
};
