import { t } from '@lingui/core/macro';
import { useMemo, useState } from 'react';

import { AudienceOptionType } from '@/api/audience';
import { TRAFFIC_TYPES } from '@/api/common';
import { WebTrafficVisitSummaryDataType } from '@/api/webTraffic';
import Flare, { Donut, FlareSeriesOptions, Legend, Tooltip } from '@/components/Flare';
import {
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  TAM_PRIMARY,
  UNKNOWN_PRIMARY,
  VISITS_PRIMARY,
} from '@/constants/colors';
import SolAPIError from '@/error/SolAPIError';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';
import { COLOR_WHITE } from '@/styles/palette';

type Props = {
  data?: WebTrafficVisitSummaryDataType[];
  audience?: AudienceOptionType;
  isLoading?: boolean;
  error: SolAPIError | null;
};

const WebTrafficTotalVisitsChart = ({ data, audience, isLoading, error }: Props) => {
  const [disabledSeries, setDisabledSeries] = useState<string[]>([TRAFFIC_TYPES.UNRESOLVED]);
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'visits',
        name: t`Visits`,
        data: data.map((slice) => ({
          id: slice.id,
          name: slice.name,
          data: slice.percentOfTotal,
          custom: {
            typeId: slice.id,
            value: slice.count,
            companyCount: slice.engagedCompanies,
            companyPercent: slice.percentOfTotalCompanies,
          },
        })),
      },
    ];
  }, [data]);

  const handleSeriesToggle = (series: string, enabled: boolean) => {
    if (enabled) {
      setDisabledSeries(disabledSeries.filter((s) => s !== series));
    } else {
      setDisabledSeries([...disabledSeries, series]);
    }
  };

  const totalVisits = data && data.length > 0 ? data[0].total : 0;

  return (
    <Flare
      data={seriesData}
      title={`<b>${numberFormat(totalVisits)}</b><br/>${t`Total Visits`}`}
      width="auto"
      height="auto"
      colors={[
        audience?.id ? AUDIENCE_PRIMARY : TAM_PRIMARY,
        VISITS_PRIMARY,
        BOT_PRIMARY,
        UNKNOWN_PRIMARY,
      ]}
      isLoading={isLoading}
      error={error}
      disabledSeriesIds={disabledSeries}
    >
      <Legend showByCategory onSeriesToggle={handleSeriesToggle} />
      <Donut
        y="data"
        dataLabelColor={COLOR_WHITE}
        dataLabelFormat={(label) => {
          if (label.y && label.y >= 0.02) {
            return numberFormat(label.y, { isPercent: true, precision: 0 });
          }
          return '';
        }}
        hideConnector
        hideDataLabels={false}
        hiddenDataLabelThreshold={0}
      />
      <Tooltip
        titleFormat={(point) => point?.name}
        rowLabelFormat={(point) => {
          if (point?.id === TRAFFIC_TYPES.TARGET || point?.id === TRAFFIC_TYPES.OTHER) {
            return `${point?.series?.name}<br/>${t`Companies`}`;
          }
          return point?.series?.name;
        }}
        rowSecondaryValueFormat={(point) => {
          const totalPercent = numberFormat(point?.y, { isPercent: true });
          if (point?.id === TRAFFIC_TYPES.TARGET || point?.id === TRAFFIC_TYPES.OTHER) {
            return `(${totalPercent})<br/>(${numberFormat(point?.custom?.companyPercent, { isPercent: true })})`;
          }
          return `(${totalPercent})`;
        }}
        rowValueFormat={(point) => {
          if (point?.id === TRAFFIC_TYPES.TARGET || point?.id === TRAFFIC_TYPES.OTHER) {
            return `${numberFormat(point?.custom?.value)}<br/>${numberFormat(point?.custom?.companyCount)}`;
          }
          return numberFormat(point?.custom?.value);
        }}
        rowValueLink={(point) => {
          if (point?.id === TRAFFIC_TYPES.TARGET) {
            if (audience?.id) {
              return ROUTES.webTrafficActivityWithParams({
                audienceIds: audience?.id,
                'metrics.visits': '[1,]',
              });
            } else {
              return ROUTES.webTrafficActivityWithParams({
                inTam: 'true',
                'metrics.visits': '[1,]',
              });
            }
          }
          if (point?.custom?.typeId === 'other') {
            if (audience?.id) {
              return ROUTES.webTrafficActivityWithParams({
                'audienceIds!': audience?.id,
                'metrics.visits': '[1,]',
              });
            } else {
              return ROUTES.webTrafficActivityWithParams({
                inTam: 'false',
                'metrics.visits': '[1,]',
              });
            }
          }
          return null;
        }}
        unstable_hasRouterContext={false}
      />
    </Flare>
  );
};

export default WebTrafficTotalVisitsChart;
