import { t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { WebTrafficCompanyBreakdownType } from '@/api/activity';
import Flare, { Donut, FlareSeriesOptions, Tooltip } from '@/components/Flare';
import { getChannelColor } from '@/constants/colors/channel';
import SolAPIError from '@/error/SolAPIError';
import { numberFormat } from '@/helper/numberFormatter';
import { COLOR_WHITE } from '@/styles/palette';

type Props = {
  data?: WebTrafficCompanyBreakdownType[];
  isLoading: boolean;
  error: SolAPIError | null;
  disabledSeries: string[];
};

const CompanyVisitDonutChart = ({ data, isLoading, error, disabledSeries }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'percent',
        name: t`Total visits`,
        data: data.map((d) => ({
          id: d.vendor.id,
          name: d.vendor.displayName,
          data: d.percentOfTotal,
          custom: { visits: d.count },
        })),
      },
    ];
  }, [data]);

  let totalVisits = 0;
  if (data && data.length > 0) {
    totalVisits = data[0].total;
  }

  return (
    <div style={{ width: 314, height: 290 }}>
      <Flare
        data={seriesData}
        title={`<b>${numberFormat(totalVisits)}</b><br/>${t`Total Visits`}`}
        width="auto"
        height="auto"
        colors={data?.map((d) => getChannelColor(d.vendor.channel))}
        isLoading={isLoading}
        error={error}
        disabledSeriesIds={disabledSeries}
      >
        <Donut
          y="data"
          dataLabelFormat={(label) => {
            if (label.y && label.y >= 0.03) {
              return numberFormat(label.y, { isPercent: true, precision: 0 });
            }
            return '';
          }}
          hideConnector
          noBorder
          dataLabelColor={COLOR_WHITE}
          hiddenDataLabelThreshold={0}
        />
        <Tooltip
          titleFormat={(point) => point?.key}
          rowValueFormat={(point) => numberFormat(point?.custom?.visits)}
          rowSecondaryValueFormat={(point) => {
            const percent = point?.y;
            return percent ? `(${numberFormat(percent, { isPercent: true, precision: 0 })})` : '';
          }}
        />
      </Flare>
    </div>
  );
};

export default CompanyVisitDonutChart;
