import gql from 'graphql-tag';
import { useMemo } from 'react';

import { QueryState, SolQueryParams } from '@/components/InstantSearch';
import {
  PIXEL_COMPARE_MAX_LIMIT,
  PIXEL_COMPARE_MIN_LIMIT,
  PIXEL_GROUP_MAX_LIMIT,
} from '@/constants/numbers';
import { useSolQuery } from '@/hooks/useSolQuery';

import { OPPORTUNITY_TYPE } from '../opportunity';
import { fetcherSol } from '../sol-fetcher';
import getVariablesFromTableParams from '../util/getVariablesFromTableParams';
import {
  PixelCreateResponseType,
  PixelCreateType,
  PixelCreateVariablesType,
  PixelGQLResponse,
  PixelImpressionTrendGQLResponse,
  PixelListGQLResponse,
  PixelMultiCostBreakdownGQLResponse,
  PixelMultiOpportunitiesInfluencedGQLResponse,
  PixelMultiReachEngagementTotalsGQLResponse,
  PixelMultiTargetingEfficiencyGQLResponse,
  PixelOpportunitiesInfluencedGQLResponse,
  PixelOpportunitiesInfluencedListGQLResponse,
  PixelReachEngagementTrendGQLResponse,
  PixelTargetingEfficiencyGQLResponse,
  PixelUpdateResponseType,
  PixelUpdateType,
  PixelUpdateVariablesType,
  PixelUsageGQLResponse,
  PixelsByIdGQLResponse,
} from './pixelType';

const DEFAULT_FILTER_FIELDS = ['name', 'audience.name', 'channel.name', 'vendor.name'];

const isPixelCompareLimitMet = (pixelIds?: string[]) =>
  pixelIds?.length &&
  pixelIds.length >= PIXEL_COMPARE_MIN_LIMIT &&
  pixelIds.length <= PIXEL_COMPARE_MAX_LIMIT;

const isPixelDetailLimitMet = (pixelIds?: string[]) =>
  !!pixelIds?.length && pixelIds.length <= PIXEL_GROUP_MAX_LIMIT;

export const usePixelList = (tableParams?: SolQueryParams) => {
  const { data, error, isLoading, mutate } = useSolQuery<PixelListGQLResponse>({
    query:
      tableParams &&
      gql`
        query GetPixelList(
          $page: Pagination!
          $sort: [SortParamInput]!
          $filter: [FilterParamInput]!
        ) {
          pixels {
            get(page: $page, sort: $sort, filter: $filter) {
              edges {
                node {
                  audience {
                    id
                    name
                  }
                  vendor {
                    id
                    name
                  }
                  channel {
                    id
                    name
                  }
                  updated_at
                  id
                  name
                  metrics {
                    impressions
                    lastImpressionDate
                  }
                  status
                }
              }
              totalEdges
            }
          }
        }
      `,
    variables: getVariablesFromTableParams(DEFAULT_FILTER_FIELDS, tableParams),
  });

  return {
    pixelListData: data?.pixels.get.edges.map((edge) => edge.node),
    totalResults: data?.pixels.get.totalEdges,
    isLoading,
    error,
    mutate,
  };
};

export const usePixelsByIds = (pixelIds?: string[]) => {
  const pixelSnippet = pixelIds
    ?.map(
      (id, index) =>
        // eslint-disable-next-line lingui/no-unlocalized-strings
        `pixel${index + 1}: getById(id: $id${index + 1}) {
          audience {
            id
            name
          }
          vendor {
            id
            name
          }
          channel {
            id
            name
            color
          }
          created_at
          created_by {
            name
          }
          updated_at
          updated_by {
            name
          }
          id
          name
          description
          code
          metrics {
            impressions
            lastImpressionDate
          }
          status
        }`,
    )
    .join('\n');

  // eslint-disable-next-line lingui/no-unlocalized-strings
  const query = `query GetPixelsByIds(${pixelIds?.map((_, index) => `$id${index + 1}: String`).join(', ')}) {
    pixels {
      ${pixelSnippet}
    }
  }`;

  const { data, isLoading, error } = useSolQuery<PixelsByIdGQLResponse>({
    query:
      isPixelDetailLimitMet(pixelIds) &&
      gql`
        ${query}
      `,
    variables: pixelIds?.reduce<Record<string, string>>((acc, id, index) => {
      acc[`id${index + 1}`] = id;
      return acc;
    }, {}),
  });

  const pixels = useMemo(
    () => data && Object.keys(data?.pixels).map((key) => data?.pixels[key]),
    [data],
  );

  return {
    pixels,
    isLoading,
    error,
  };
};

export const usePixelById = (pixelId?: string) => {
  const { data, isLoading, error, mutate } = useSolQuery<PixelGQLResponse>({
    query:
      !!pixelId &&
      gql`
        query GetPixelById($getByIdId: String) {
          pixels {
            getById(id: $getByIdId) {
              audience {
                id
                name
              }
              vendor {
                id
                name
              }
              channel {
                id
                name
              }
              created_at
              created_by {
                name
              }
              updated_at
              updated_by {
                name
              }
              id
              name
              description
              code
              metrics {
                impressions
                lastImpressionDate
              }
              status
            }
          }
        }
      `,
    variables: {
      getByIdId: pixelId,
    },
  });

  return {
    pixel: data?.pixels.getById,
    isLoading,
    error,
    mutate,
  };
};

export const mutatePixelCreate = async (pixel: PixelCreateType) => {
  const variables: PixelCreateVariablesType = {
    pixel,
  };

  return await fetcherSol<PixelCreateVariablesType, PixelCreateResponseType>({
    query: gql`
      mutation CreatePixel($pixel: CreatePixelInput!) {
        pixels {
          create(pixel: $pixel) {
            audience {
              id
              name
            }
            vendor {
              id
              name
            }
            channel {
              id
              name
            }
            updated_at
            id
            name
            description
            code
            metrics {
              impressions
            }
            status
          }
        }
      }
    `,
    variables,
  });
};

export const mutatePixelUpdate = async (pixel: PixelUpdateType) => {
  const variables: PixelUpdateVariablesType = {
    pixel,
  };

  return await fetcherSol<PixelUpdateVariablesType, PixelUpdateResponseType>({
    query: gql`
      mutation UpdatePixel($pixel: UpdatePixelInput!) {
        pixels {
          update(pixel: $pixel) {
            audience {
              id
              name
            }
            vendor {
              id
              name
            }
            channel {
              id
              name
            }
            updated_at
            id
            name
            description
            code
            metrics {
              impressions
            }
            status
          }
        }
      }
    `,
    variables,
  });
};

export const usePixelUsageVisualizationData = () => {
  const { data, isLoading, error } = useSolQuery<PixelUsageGQLResponse>({
    query: gql`
      query PixelUsageVisualizationQuery {
        visualization {
          pixelMonthlyImpressions {
            impressions {
              actual
              firstDayOfMonth
              projected
            }
          }
          pixelUsageImpressions {
            last30Days
            recommendedAllocation
            remaining
            runOutDate
            served
          }
        }
      }
    `,
  });

  return {
    impressionMonthlyData: data?.visualization.pixelMonthlyImpressions.impressions,
    impressionKPIData: data?.visualization.pixelUsageImpressions,
    isLoading,
    error,
  };
};

export const usePixelReachEngagementTrend = (
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceIds?: string[],
) => {
  const canExecuteQuery = startDate?.length && endDate?.length && isPixelDetailLimitMet(pixelIds);

  const { data, isLoading, error } = useSolQuery<PixelReachEngagementTrendGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query PixelReachEngagementV3(
          $pixelIds: [String!]!
          $startDate: DateTime
          $endDate: DateTime
          $audienceIds: [String]
          $isCombined: Boolean!
        ) {
          visualization {
            pixelReachEngagementV3(
              pixelIds: $pixelIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
              isCombined: $isCombined
            ) {
              data {
                sundayOfWeek
                reach
                engagement
                audienceSize
              }
            }
          }
        }
      `,
    variables: {
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds,
      isCombined: true,
    },
  });

  return {
    pixelReachEngagementTrend: data?.visualization.pixelReachEngagementV3[0].data,
    isLoading,
    error,
  };
};

export const usePixelImpressionTrend = (
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceIds?: string[],
) => {
  const canExecuteQuery = startDate?.length && endDate?.length && isPixelDetailLimitMet(pixelIds);

  const { data, isLoading, error } = useSolQuery<PixelImpressionTrendGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query PixelImpressionTrend(
          $pixelIds: [String!]!
          $startDate: DateTime
          $endDate: DateTime
          $audienceIds: [String]
        ) {
          visualization {
            pixelImpressionTrend(
              pixelIds: $pixelIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
            ) {
              totalImpressions
              data {
                sundayOfWeek
                impressions
              }
            }
          }
        }
      `,
    variables: {
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds,
    },
  });

  return {
    pixelImpressionTrend: data?.visualization.pixelImpressionTrend?.[0],
    isLoading,
    error,
  };
};

export const usePixelOpportunitiesInfluenced = (
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceIds?: string[],
) => {
  const canExecuteQuery = startDate && endDate && isPixelDetailLimitMet(pixelIds);

  const { data, isLoading, error } = useSolQuery<PixelOpportunitiesInfluencedGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query PixelOpportunitiesInfluenced(
          $pixelIds: [String!]!
          $startDate: DateTime
          $endDate: DateTime
          $audienceIds: [String]
        ) {
          visualization {
            pixelOpportunitiesInfluenced(
              pixelIds: $pixelIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
            ) {
              pipeline {
                data {
                  sundayOfWeek
                  opportunities
                  revenue
                }
                totalOpportunities
                totalRevenue
              }
              recentDeals {
                data {
                  sundayOfWeek
                  opportunities
                  revenue
                }
                totalOpportunities
                totalRevenue
              }
            }
          }
        }
      `,
    variables: {
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds,
    },
  });

  return {
    pixelOpportunitiesInfluenced: data?.visualization.pixelOpportunitiesInfluenced[0],
    isLoading,
    error,
  };
};

export const usePixelOpportunitiesInfluencedList = (
  pageState: Omit<QueryState, 'search' | 'filters'>,
  opportunityType: OPPORTUNITY_TYPE,
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceIds?: string[],
) => {
  const canExecuteQuery = startDate && endDate && isPixelDetailLimitMet(pixelIds);
  // The 'date' column doesn't actually exist for an opportunity. We'll change it to either an open
  // or closed date based on the influenceType.
  if (pageState.sortBy === 'date') {
    pageState = {
      ...pageState,
      sortBy: opportunityType === OPPORTUNITY_TYPE.PIPELINE ? 'openDate' : 'closeDate',
    };
  }

  const { data, isLoading, error } = useSolQuery<PixelOpportunitiesInfluencedListGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query PixelOpportunitiesInfluencedList(
          $pixelIds: [String!]!
          $sort: [SortParamInput]!
          $startDate: DateTime
          $endDate: DateTime
          $audienceIds: [String]
          $influenceType: OppInfluence
          $page: Pagination!
        ) {
          visualization {
            pixelOpportunitiesInfluencedList(
              pixelIds: $pixelIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
              influenceType: $influenceType
              page: $page
              sort: $sort
            ) {
              edges {
                node {
                  id
                  name
                  metrics {
                    impressions
                    visits
                  }
                  openDate
                  closeDate
                  amount
                  account {
                    name
                    company {
                      name
                    }
                  }
                }
              }
              totalEdges
            }
          }
        }
      `,
    variables: {
      influenceType: opportunityType,
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds,
      page: {
        offset: (pageState.page - 1) * pageState.size,
        limit: pageState.size,
      },
      sort: {
        field: pageState.sortBy,
        direction: pageState.sortOrder,
      },
    },
  });

  return {
    opportunitiesListData: data?.visualization.pixelOpportunitiesInfluencedList.edges.map(
      (edge) => edge.node,
    ),
    totalResults: data?.visualization.pixelOpportunitiesInfluencedList.totalEdges,
    isLoading,
    error,
  };
};

export const usePixelTargetingEfficiency = (
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
  audienceIds?: string[],
) => {
  const canExecuteQuery = startDate && endDate && isPixelDetailLimitMet(pixelIds);

  const { data, isLoading, error } = useSolQuery<PixelTargetingEfficiencyGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query PixelTargetingEfficiencyV3(
          $pixelIds: [String!]!
          $startDate: DateTime
          $endDate: DateTime
          $audienceIds: [String]
        ) {
          visualization {
            pixelTargetingEfficiencyV3(
              pixelIds: $pixelIds
              startDate: $startDate
              endDate: $endDate
              audienceIds: $audienceIds
            ) {
              totals {
                all
                tam
                audience
                bot
                unresolved
                otherNonTam
                otherNonAudience
              }
            }
          }
        }
      `,
    variables: {
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds,
    },
  });

  return {
    pixelTargetingEfficiencyData: data?.visualization.pixelTargetingEfficiencyV3.totals,
    isLoading,
    error,
  };
};

export const useMultiplePixelTargetingEfficiency = (
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length && isPixelCompareLimitMet(pixelIds);

  const { data, isLoading, error } = useSolQuery<PixelMultiTargetingEfficiencyGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query PixelTargetingEfficiencyV3(
          $pixelIds: [String!]!
          $startDate: DateTime
          $endDate: DateTime
        ) {
          visualization {
            pixelTargetingEfficiencyV3(
              pixelIds: $pixelIds
              startDate: $startDate
              endDate: $endDate
            ) {
              pixels {
                pixel {
                  id
                  name
                  audience {
                    id
                    name
                  }
                }
                stats {
                  all
                  tam
                  audience
                  bot
                  unresolved
                  otherNonTam
                  otherNonAudience
                }
              }
            }
          }
        }
      `,
    variables: {
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  return {
    pixelTargetingEfficiencyData: data?.visualization.pixelTargetingEfficiencyV3,
    isLoading,
    error,
  };
};

export const useMultiplePixelCostBreakdownVisualizationData = (
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length && isPixelCompareLimitMet(pixelIds);

  const { data, isLoading, error } = useSolQuery<PixelMultiCostBreakdownGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query MultiPixelCostBreakdownVisualization(
          $pixelIds: [String!]!
          $startDate: DateTime
          $endDate: DateTime
        ) {
          visualization {
            pixelCostBreakdown(pixelIds: $pixelIds, startDate: $startDate, endDate: $endDate) {
              cpm {
                all
                audience
                tam
              }
              cpv {
                all
                audience
                tam
              }
              pixel {
                id
                name
                audience {
                  id
                  name
                }
              }
            }
          }
        }
      `,
    variables: {
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  return {
    pixelCostBreakdownData: data?.visualization.pixelCostBreakdown,
    isLoading,
    error,
  };
};

export const useMultiplePixelOpportunitiesInfluenced = (
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length && isPixelCompareLimitMet(pixelIds);

  const { data, isLoading, error } = useSolQuery<PixelMultiOpportunitiesInfluencedGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query PixelOpportunitiesInfluenced(
          $pixelIds: [String!]!
          $startDate: DateTime
          $endDate: DateTime
        ) {
          visualization {
            pixelOpportunitiesInfluenced(
              pixelIds: $pixelIds
              startDate: $startDate
              endDate: $endDate
            ) {
              pixel {
                id
                name
                audience {
                  name
                }
              }
              pipeline {
                totalOpportunities
                totalRevenue
              }
              recentDeals {
                totalOpportunities
                totalRevenue
              }
            }
          }
        }
      `,
    variables: {
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  return {
    pixelOpportunitiesInfluenced: data?.visualization.pixelOpportunitiesInfluenced,
    isLoading,
    error,
  };
};

export const useMultiplePixelReachEngagementData = (
  pixelIds?: string[],
  startDate?: string,
  endDate?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length && isPixelCompareLimitMet(pixelIds);

  const { data, isLoading, error } = useSolQuery<PixelMultiReachEngagementTotalsGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query PixelMultiReachEngagementTotals(
          $pixelIds: [String!]!
          $startDate: DateTime!
          $endDate: DateTime!
        ) {
          visualization {
            pixelReachEngagementTotals(
              pixelIds: $pixelIds
              startDate: $startDate
              endDate: $endDate
            ) {
              engagement
              reach
              audienceSize
              pixel {
                id
                name
                audience {
                  id
                  name
                }
              }
            }
          }
        }
      `,
    variables: {
      pixelIds,
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  return {
    pixelReachEngagementTotalsData: data?.visualization.pixelReachEngagementTotals,
    isLoading,
    error,
  };
};
