import { Trans } from '@lingui/react/macro';
import { ReactNode } from 'react';

import { usePixelsByIds } from '@/api/pixel';
import Error404 from '@/components/errors/Error404';
import PageError from '@/components/errors/PageError';
import SolAPIError from '@/error/SolAPIError';

type Props = {
  pixelIds?: string[];
  minLimit?: number;
  maxLimit: number;
  children: ReactNode;
};

const PixelDetailErrorOrChildren = ({ pixelIds, maxLimit, minLimit = 1, children }: Props) => {
  const { error } = usePixelsByIds(pixelIds);

  if (pixelIds?.length && pixelIds.length > maxLimit) {
    return (
      <PageError
        message={<Trans>Maximum limit of pixels reached</Trans>}
        detail={
          <Trans>
            Only {maxLimit} pixels can be viewed in this report. Please remove some pixels to
            continue.
          </Trans>
        }
      />
    );
  }

  if (pixelIds?.length && pixelIds.length < minLimit) {
    return (
      <PageError
        message={<Trans>Minimum limit of pixels has not been reached</Trans>}
        detail={
          <Trans>
            At least {minLimit} pixels must be chosen to view this report. Please add more pixels to
            continue.
          </Trans>
        }
      />
    );
  }

  if (error instanceof SolAPIError && error.is404()) {
    if (pixelIds?.length && pixelIds.length === 1) {
      return <Error404 message={<Trans>The pixel you're looking for does not exist</Trans>} />;
    }
    return (
      <Error404
        message={<Trans>One or more of the pixels you're looking for do not exist</Trans>}
      />
    );
  }

  return children;
};

export default PixelDetailErrorOrChildren;
