import { LDFlagValue } from 'launchdarkly-js-client-sdk';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import C99FeatureFlagEvent, { C99FeatureFlagEventType } from '@/events/C99FeatureFlagEvent';

export const FLAG_STORAGE_KEY = 'c99-flag-overrides';

export type C99FlagSet = Record<FEATURE_FLAG, LDFlagValue>;

// Add new feature flags to this enum
// feature flags with `-` or `.` characters with be converted to camel-case.
export enum FEATURE_FLAG {
  darkTheme = 'darkTheme',
  aiChat = 'aiChat',
  websockets = 'websockets',
  offlineEvents = 'offlineEvents',
}

const getFeatureFlagOverrides = (): C99FlagSet => {
  return JSON.parse(localStorage.getItem(FLAG_STORAGE_KEY) ?? `{}`);
};

export const useFlags = (): C99FlagSet => {
  const [flagOverrides, setFlagOverrides] = useState(getFeatureFlagOverrides());

  useEffect(() => {
    const handleStorageChange = (event: C99FeatureFlagEvent) => {
      const { detail } = event;
      if (detail.key === FLAG_STORAGE_KEY) {
        setFlagOverrides(detail.newValue ?? {});
      }
    };

    window.addEventListener(C99FeatureFlagEventType, handleStorageChange);
    return () => window.removeEventListener(C99FeatureFlagEventType, handleStorageChange);
  }, []);

  const flags = useLDFlags<C99FlagSet>();
  const combinedFlags = {
    ...flags,
    ...flagOverrides,
  };

  return combinedFlags;
};

export const useFeatureFlag = (flag: FEATURE_FLAG) => {
  const featureFlags = useFlags();
  return featureFlags[flag];
};

export const overrideFeatureFlag = (flag: FEATURE_FLAG, value: boolean) => {
  const flagOverrides = getFeatureFlagOverrides();
  const newFlagOverrides = {
    ...flagOverrides,
    [flag]: value,
  };
  localStorage.setItem(FLAG_STORAGE_KEY, JSON.stringify(newFlagOverrides));
  window.dispatchEvent(
    new C99FeatureFlagEvent(C99FeatureFlagEventType, {
      detail: {
        key: FLAG_STORAGE_KEY,
        oldValue: flagOverrides,
        newValue: newFlagOverrides,
      },
    }),
  );
};
