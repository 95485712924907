import { t } from '@lingui/core/macro';
import gql from 'graphql-tag';
import { useMemo } from 'react';

import { useSolImmutableQuery } from '@/hooks/useSolQuery';

import { RegionsGQLResponse, RevenueRangesGQLResponse, SectorsGQLResponse } from './settingsType';

export const useSectors = ({ includeBlanks = false } = {}) => {
  const { data, isLoading, error } = useSolImmutableQuery<SectorsGQLResponse>({
    query: gql`
      query getSectors(
        $page: Pagination!
        $sort: [SortParamInput]!
        $filter: [FilterParamInput]!
        $queryId: String
      ) {
        sectors {
          get(page: $page, sort: $sort, filter: $filter, queryId: $queryId) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      page: {
        limit: 50,
        offset: 0,
      },
      sort: {
        direction: 'asc',
        field: 'name',
      },
      filter: [],
    },
  });

  const sectors = useMemo(() => {
    const sectors = data?.sectors.get.edges.map((edge) => edge.node);

    if (sectors && includeBlanks) {
      return [
        {
          id: '',
          name: t`(Blanks)`,
        },
        ...sectors,
      ];
    }
    return sectors;
  }, [data, includeBlanks]);

  return {
    sectors,
    isLoading,
    error,
  };
};

export const useRegions = ({ includeBlanks = false } = {}) => {
  const { data, isLoading, error } = useSolImmutableQuery<RegionsGQLResponse>({
    query: gql`
      query GetRegions(
        $page: Pagination!
        $sort: [SortParamInput]!
        $filter: [FilterParamInput]!
        $queryId: String
      ) {
        regions {
          get(page: $page, sort: $sort, filter: $filter, queryId: $queryId) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      page: {
        limit: 50,
        offset: 0,
      },
      sort: {
        direction: 'asc',
        field: 'name',
        nullsFirst: includeBlanks,
      },
      filter: [],
    },
  });

  const regions = useMemo(() => {
    const regions = data?.regions.get.edges
      .map((edge) => edge.node)
      .filter((region) => region.id !== 'regn_antarctica');

    if (regions && includeBlanks) {
      return [
        {
          id: '',
          name: t`(Blanks)`,
        },
        ...regions,
      ];
    }
    return regions;
  }, [data, includeBlanks]);

  return {
    regions,
    isLoading,
    error,
  };
};

export const useRevenueRanges = ({ includeBlanks = false } = {}) => {
  const { data, isLoading, error } = useSolImmutableQuery<RevenueRangesGQLResponse>({
    query: gql`
      query GetRevRanges(
        $page: Pagination!
        $sort: [SortParamInput]!
        $filter: [FilterParamInput]!
        $queryId: String
      ) {
        revRanges {
          get(page: $page, sort: $sort, filter: $filter, queryId: $queryId) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      page: {
        limit: 50,
        offset: 0,
      },
      sort: {
        direction: 'asc',
        field: 'name',
        nullsFirst: includeBlanks,
      },
      filter: [],
    },
  });

  const revRanges = useMemo(() => {
    const revRanges = data?.revRanges.get.edges.map((edge) => edge.node);

    if (revRanges && includeBlanks) {
      return [
        {
          id: '',
          name: t`(Blanks)`,
        },
        ...revRanges,
      ];
    }
    return revRanges;
  }, [data, includeBlanks]);

  return {
    revRanges,
    isLoading,
    error,
  };
};
