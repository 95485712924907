import { Point, PointerEventObject } from 'highcharts';
import { useCallback, useState } from 'react';

import { OpportunityType } from '@/api/opportunity';
import ClosedWonNonDigitalEventsChart from '@/app/closed-won/ClosedWonNonDigitalEventsChart';
import ClosedWonTrendChart from '@/app/closed-won/ClosedWonTrendChart';
import { FlareChart } from '@/components/Flare';
import { Flex } from '@/components/Flex';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import { getNearestPointFromXValue } from '@/helper/highcharts-util';

const CHART_MARGIN_RIGHT = 160;
const CHART_MARGIN_LEFT = 100;

type Props = {
  opportunity?: OpportunityType;
};

const ClosedWonContent = ({ opportunity }: Props) => {
  const [trendChart, setTrendChart] = useState<FlareChart>();
  const [nonDigitalEventsChart, setNonDigitalEventsChart] = useState<FlareChart>();
  const isOfflineEventsEnabled = useFeatureFlag(FEATURE_FLAG.offlineEvents);

  const drawCrosshair = (
    chart: FlareChart | undefined,
    event: PointerEventObject,
    pointX: number,
  ) => {
    if (chart?.xAxis?.[0]) {
      const nearestPoint = getNearestPointFromXValue(chart, pointX);

      if (nearestPoint) {
        chart.xAxis[0].drawCrosshair(event, nearestPoint);
      } else {
        chart.xAxis[0].hideCrosshair();
      }
    }
  };

  const handleHoverChart = useCallback(
    (event: PointerEventObject, point: Point) => {
      if (isOfflineEventsEnabled) {
        drawCrosshair(trendChart, event, point.x);
        drawCrosshair(nonDigitalEventsChart, event, point.x);
      }
    },
    [trendChart, nonDigitalEventsChart, isOfflineEventsEnabled],
  );

  const handleHoverLeaveChart = useCallback(() => {
    if (isOfflineEventsEnabled) {
      if (trendChart?.xAxis?.[0]) {
        trendChart.xAxis[0].hideCrosshair();
      }

      if (nonDigitalEventsChart?.xAxis?.[0]) {
        nonDigitalEventsChart.xAxis[0].hideCrosshair();
      }
    }
  }, [trendChart, nonDigitalEventsChart, isOfflineEventsEnabled]);

  return (
    <Flex gap="large" vertical>
      <ClosedWonTrendChart
        opportunity={opportunity}
        marginLeft={CHART_MARGIN_LEFT}
        marginRight={CHART_MARGIN_RIGHT}
        onHoverChart={handleHoverChart}
        onHoverLeaveChart={handleHoverLeaveChart}
        onPostRenderChart={setTrendChart}
      />
      {isOfflineEventsEnabled && (
        <ClosedWonNonDigitalEventsChart
          opportunity={opportunity}
          marginLeft={CHART_MARGIN_LEFT}
          marginRight={CHART_MARGIN_RIGHT}
          onHoverChart={handleHoverChart}
          onHoverLeaveChart={handleHoverLeaveChart}
          onPostRenderChart={setNonDigitalEventsChart}
        />
      )}
    </Flex>
  );
};

export default ClosedWonContent;
