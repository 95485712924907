/* eslint-disable lingui/no-unlocalized-strings */
import * as Sentry from '@sentry/react';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';

import {
  ClientMessagePayload,
  ClientMessageType,
  WebSocketError,
  WebSocketResponse,
} from './message';

type AuthWebSocketParams = {
  onMessageReceived?: (message: WebSocketResponse) => void;
  onErrorReceived?: (message: WebSocketError) => void;
};

const useAuthWebSocket = ({ onMessageReceived, onErrorReceived }: AuthWebSocketParams) => {
  const canUseWebsockets = useFeatureFlag(FEATURE_FLAG.websockets);
  const { session } = useStytchB2BClient();
  const stopSpanRef = useRef<() => void>();

  const jwtToken = session.getTokens()?.session_jwt;

  const socket = useWebSocket<WebSocketResponse>(
    canUseWebsockets ? import.meta.env.VITE_WEBSOCKET_URL : null,
    {
      protocols: jwtToken,
      onOpen: () => {
        Sentry.addBreadcrumb({
          type: 'default',
          category: 'Nebula Websocket Opened',
          message: 'Socket opened',
          level: 'info',
        });
      },
      onClose: (event) => {
        Sentry.addBreadcrumb({
          type: 'default',
          category: 'Nebula Websocket Closed',
          message: 'Socket closed',
          data: {
            code: event.code,
            reason: event.reason,
          },
          level: 'error',
        });
      },
      onError: (event) => {
        Sentry.captureException(new Error('Websocket error'));
        console.error('socket error:', event);
      },
      reconnectAttempts: 5,
      shouldReconnect: () => true,
      onMessage: (event: MessageEvent) => {
        const eventData: WebSocketResponse | WebSocketError = JSON.parse(event.data);
        if (eventData) {
          if (eventData.type === 'complete' || eventData.type === 'error') {
            Sentry.addBreadcrumb({
              type: 'default',
              category: 'Nebula Chat Response',
              message:
                eventData.type === 'error' ? 'Nebula error received' : 'Nebula message received',
              data: eventData,
              level: eventData.type === 'error' ? 'error' : 'info',
            });
          }

          if (eventData.type === 'error') {
            Sentry.captureException(new Error(eventData.payload.code), {
              tags: {
                'nebula:code': eventData.payload.code,
                'nebula:message': eventData.payload.content,
                'nebula:sessionId': eventData.payload.sessionId,
              },
            });
            onErrorReceived?.(eventData);
          } else {
            onMessageReceived?.(eventData);
          }

          if (eventData.type === 'complete' || eventData.type === 'error') {
            // end the span if the full message has been fully streamed or an error occurred
            stopSpanRef.current?.();
          }
        }
      },
    },
  );

  const handleSendMessage = (payload: ClientMessagePayload) => {
    Sentry.startSpanManual({ name: 'Nebula websocket message' }, (span, finishSpan) => {
      Sentry.addBreadcrumb({
        type: 'user',
        category: 'Nebula Chat Request',
        message: payload.content,
        data: payload,
        level: 'info',
      });

      const message: ClientMessageType = {
        type: 'message',
        token: jwtToken,
        payload,
        sentryMetadata: {
          sentryTrace: Sentry.spanToTraceHeader(span),
          baggage: Sentry.spanToBaggageHeader(span),
        },
      };

      socket?.sendJsonMessage(message);

      // Conclude the span when we receive the response
      stopSpanRef.current = finishSpan;
    });
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[socket?.readyState ?? ReadyState.UNINSTANTIATED];

  return {
    onSendMessage: handleSendMessage,
    connectionStatus,
  };
};

export default useAuthWebSocket;
