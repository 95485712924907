import gql from 'graphql-tag';

import { SolQueryParamsNew } from '@/components/InstantSearch';
import { useSolQuery } from '@/hooks/useSolQuery';

import { fetcherSol } from '../sol-fetcher';
import { getVariablesFromTableParamsNew } from '../util/getVariablesFromTableParams';
import {
  UserDeleteResponseType,
  UserDeleteVariableType,
  UserDetailGQLResponse,
  UserInviteResponseType,
  UserInviteVariableType,
  UserListGQLResponse,
  UserRoleType,
  UserUpdateResponseType,
  UserUpdateVariableType,
} from './userType';

export const useUserList = (tableParams?: SolQueryParamsNew) => {
  const variables = getVariablesFromTableParamsNew(tableParams);

  const { data, isLoading, error, mutate } = useSolQuery<UserListGQLResponse>({
    query:
      tableParams &&
      gql`
        query GetUserList($page: Pagination!, $sort: [SortParamInput]!, $searchQuery: String) {
          users {
            get(page: $page, sort: $sort, searchQuery: $searchQuery) {
              edges {
                node {
                  id
                  name
                  email
                  status
                  role
                }
              }
              totalEdges
            }
          }
        }
      `,
    variables: {
      page: variables?.page,
      sort: variables?.sort,
      searchQuery: variables?.searchQuery,
    },
  });

  return {
    userListData: data?.users.get.edges.map((edge) => edge.node),
    totalResults: data?.users.get.totalEdges,
    isLoading,
    error,
    mutate,
  };
};

export const useUserById = (userId?: string) => {
  const { data, isLoading, error, mutate } = useSolQuery<UserDetailGQLResponse>({
    query:
      !!userId &&
      gql`
        query GetUserById($userId: String!) {
          users {
            getById(id: $userId) {
              id
              name
              email
              status
              role
            }
          }
        }
      `,
    variables: {
      userId,
    },
  });

  return {
    user: data?.users.getById,
    isLoading,
    error,
    mutate,
  };
};

export const mutateUserUpdate = async (id: string, name: string, role: UserRoleType) => {
  return await fetcherSol<UserUpdateVariableType, UserUpdateResponseType>({
    query: gql`
      mutation UpdateUser($user: UpdateUserInput!) {
        users {
          update(user: $user) {
            id
          }
        }
      }
    `,
    variables: {
      user: {
        id,
        name,
        role,
      },
    },
  });
};

export const mutateUserDelete = async (id: string) => {
  const response = await fetcherSol<UserDeleteVariableType, UserDeleteResponseType>({
    query: gql`
      mutation DeleteUser($deleteId: String!) {
        users {
          delete(id: $deleteId) {
            success
          }
        }
      }
    `,
    variables: {
      deleteId: id,
    },
  });

  return response.users.delete;
};

export const mutateUserInvite = async (name: string, email: string, role: UserRoleType) => {
  const response = await fetcherSol<UserInviteVariableType, UserInviteResponseType>({
    query: gql`
      mutation InviteUser($email: String!, $name: String!, $role: UserRole!) {
        users {
          invite(email: $email, name: $name, role: $role) {
            success
          }
        }
      }
    `,
    variables: {
      email,
      name,
      role,
    },
  });

  return response.users.invite;
};
