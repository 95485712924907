import { useEffect } from 'react';

import { FlareChart } from '@/components/Flare';

const useHighchartsHoverLeave = (
  chart?: FlareChart,
  onHoverLeaveChart?: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    if (onHoverLeaveChart && chart) {
      if (chart?.container) {
        chart.container.addEventListener('mouseout', onHoverLeaveChart);
      }

      return () => {
        if (chart?.container) {
          chart.container.removeEventListener('mouseout', onHoverLeaveChart);
        }
      };
    }
  }, [chart, onHoverLeaveChart]);
};

export default useHighchartsHoverLeave;
