import { Fragment } from 'react/jsx-runtime';

import SkeletonLoader from '@/components/SkeletonLoader';

const ClosedWonNonDigitalEventsLoader = ({ numRows = 5 }) => {
  const rowHeight = 8;
  const vGap = 32;

  const calculatedHeight = numRows * (rowHeight + vGap) + 5;

  let yCursor = vGap;

  const renderRow = (rowIndex: number, height: number) => {
    const row = (
      <Fragment key={rowIndex}>
        <rect x={50} y={yCursor} width={820} height={height} rx={3} ry={4} />
        <rect x={880} y={yCursor - 5} width={80} height={height + 10} rx={3} ry={4} />
      </Fragment>
    );

    yCursor += height + vGap;
    return row;
  };

  return (
    <SkeletonLoader
      viewBox={`0 0 1000 ${calculatedHeight}`}
      height={calculatedHeight}
      width="100%"
      preserveAspectRatio="none"
    >
      {[...Array(numRows)].map((d, i) => renderRow(i, rowHeight))}
    </SkeletonLoader>
  );
};

export default ClosedWonNonDigitalEventsLoader;
