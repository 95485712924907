import { Options, PointOptionsObject, SeriesLineOptions } from 'highcharts';
import { useEffect } from 'react';

import { COLOR_GRAY_100, COLOR_GRAY_700 } from '@/styles/palette';

import { useFlareContext } from '../FlareContext';
import LineLoader from '../loaders/LineLoader';
import {
  FlareChart,
  FlareDataPointOptions,
  FlareSeriesOptions,
  SanitizedFlareProps,
} from '../types';
import { getSelectionLine } from '../utils/mark-util';
import styles from './Line.module.scss';

type LineProps = {
  x?: string;
  y?: string;
};

const buildLineOptions = (
  options: Options,
  parentProps: SanitizedFlareProps,
  props: LineProps,
): Options => {
  const { data, colors, parseX, parseY } = parentProps;
  const { x = 'x', y = 'y' } = props;

  const series: SeriesLineOptions[] | undefined = data?.map(
    (series: FlareSeriesOptions, seriesIndex: number) => {
      const { name, data: seriesData, ...rest } = series;

      const newSeriesData = seriesData.map((item: FlareDataPointOptions) => {
        const newItem: PointOptionsObject = {
          ...item,
          x: parseX(item[x]),
          y: parseY(item[y]),
          colorIndex: seriesIndex,
        };
        delete newItem[x as keyof PointOptionsObject];
        delete newItem[y as keyof PointOptionsObject];

        return newItem;
      });

      return {
        type: 'line',
        ...rest,
        name,
        color: colors[seriesIndex],
        lineColor: colors[seriesIndex],
        fillOpacity: 0.25,
        data: newSeriesData,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        marker: {
          enabled: false,
          radius: 0.1,
          fillOpacity: 1,
          fillColor: colors[seriesIndex],
          states: {
            hover: {
              enabled: true,
              fillColor: undefined,
              lineColor: COLOR_GRAY_100,
              lineWidth: 1,
              radius: 5,
              animation: {
                duration: 200,
              },
            },
            select: {
              enabled: true,
              fillColor: undefined,
              lineColor: COLOR_GRAY_100,
              lineWidth: 1,
              radius: 8,
              animation: {
                duration: 200,
              },
            },
            normal: {
              animation: {
                duration: 200,
              },
            },
          },
        },
      };
    },
  );

  return {
    ...options,
    chart: {
      ...options.chart,
      type: 'line',
    },
    series,
    plotOptions: {
      ...options.plotOptions,
      series: {
        ...options.plotOptions?.series,
        point: {
          ...options.plotOptions?.series?.point,
          events: {
            ...options.plotOptions?.series?.point?.events,

            select: function () {
              setTimeout(() => {
                const chart = this.series.chart as FlareChart;

                if (
                  chart.getSelectedPoints().length &&
                  chart.selectedBackground?.xValue !== this.x
                ) {
                  const line = chart.renderer
                    .path(getSelectionLine(chart))
                    .addClass(styles.selectionLine)
                    .attr({
                      stroke: COLOR_GRAY_700,
                    })
                    .add();

                  chart.selectedBackground = {
                    xValue: this.x,
                    graphic: line,
                  };
                }
              }, 0);
            },
            unselect: function () {
              const chart = this.series.chart as FlareChart;

              const { graphic: rect } = chart?.selectedBackground ?? {};
              if (rect) {
                rect.destroy();
              }
              chart.selectedBackground = undefined;
            },
          },
        },
      },
      line: {
        marker: {
          enabled: true,
          symbol: 'circle',
        },
      },
    },
  };
};

const Line = (props: LineProps) => {
  const { id, registerChild, isLoading } = useFlareContext();

  useEffect(() => {
    registerChild(id, (options: Options, parentProps: SanitizedFlareProps) =>
      buildLineOptions(options, parentProps, props),
    );
  }, [props]);

  if (isLoading) {
    return <LineLoader style={{ flex: 1 }} />;
  }

  return null;
};

export default Line;
