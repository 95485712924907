import { SolError } from '@/api/solType';

import { CORE_ERROR_CODES } from './messages/core-standard-errors';
import getSolStandardError from './sol-standard-errors';

class SolAPIError extends Error {
  requestId?: string;
  errors: Record<string, SolError> = {};
  numErrors: number;

  constructor(requestId: string | undefined, errors: SolError[]) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    super(`Sol API Error`);

    const standardErrors = errors.reduce<Record<string, SolError>>((memo, error) => {
      const standardError: SolError = getSolStandardError(error);
      memo[standardError.extensions.code] = standardError;
      return memo;
    }, {});

    this.requestId = requestId;
    this.errors = standardErrors;
    this.numErrors = errors.length;

    Object.setPrototypeOf(this, SolAPIError.prototype);
  }

  is404() {
    return this.errors[CORE_ERROR_CODES.NOT_FOUND] != null;
  }

  getFirstError() {
    return Object.values(this.errors)[0];
  }

  toString() {
    const errorMessages = Object.values(this.errors)
      .map((error) => `- "${error.extensions.code}": ${error.message}`)
      .join('\n');

    // eslint-disable-next-line lingui/no-unlocalized-strings
    return `${this.message}
Request Id: "${this.requestId}"

Errors (${this.numErrors}):
${errorMessages}`;
  }
}

export default SolAPIError;
