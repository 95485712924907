import {
  AlignValue,
  AxisLabelsFormatterContextObject,
  Axis as AxisType,
  AxisTypeValue,
  OptionsTickmarkPlacementValue,
  XAxisOptions,
  XAxisPlotLinesOptions,
  YAxisOptions,
  YAxisPlotLinesOptions,
} from 'highcharts';
import set from 'lodash/set';
import { useEffect } from 'react';

import { CROSSHAIR_BACKGROUND, CROSSHAIR_LINE_COLOR, GRID_LINE_COLOR } from '@/constants/colors';

import { useFlareContext } from '../FlareContext';
import { SanitizedFlareProps } from '../types';

type PositionValue = 'left' | 'right' | 'top' | 'bottom';

type AxisProps = {
  type?: Exclude<AxisTypeValue, 'treegrid'>;
  title?: string;
  description?: string;
  categories?: string[];
  position?: PositionValue;
  crosshair?: 'rect' | 'line'; // if "rect", the tooltip must be in "shared" mode
  labelFormat?: (item: AxisLabelsFormatterContextObject) => void;
  labelsNoWrap?: boolean;
  plotLines?: YAxisPlotLinesOptions[] | XAxisPlotLinesOptions[];
  align?: AlignValue;
  useHTML?: boolean;
  baselineWidth?: number;
  gridlineWidth?: number;
  labelDistance?: number;
  opposite?: boolean;
  reversed?: boolean;
  tickAmount?: number;
  tickmarkPlacement?: OptionsTickmarkPlacementValue;
  tickPixelInterval?: number;
  tickPositions?: number[];
  tickLength?: number;
  tickWidth?: number;
  allowDecimals?: boolean;
  min?: number;
  max?: number;

  /**
   * Two unique `dualAxisId` strings should be used for a dual x or y axis.
   */
  dualAxisId?: string;
};

type AxisOptions = XAxisOptions | YAxisOptions;

export interface CategoryAxisType extends AxisType {
  transA: number; // The height of the category range.
}

const configureDualAxis = (
  parentProps: SanitizedFlareProps,
  existingAxis: AxisOptions,
  newAxis: AxisOptions,
) => {
  const { textColors } = parentProps;

  set(existingAxis, 'labels.style.color', textColors[0]);
  set(newAxis, 'labels.style.color', textColors[1]);

  return [existingAxis, newAxis];
};

const buildAxisOptions = (
  options: Highcharts.Options,
  parentProps: SanitizedFlareProps,
  props: AxisProps,
) => {
  const {
    type = 'linear',
    title,
    description,
    categories,
    position,
    crosshair,
    labelDistance = 10,
    labelsNoWrap,
    opposite,
    reversed,
    tickAmount,
    tickmarkPlacement,
    tickPixelInterval,
    tickPositions,
    tickLength,
    tickWidth,
    labelFormat,
    plotLines,
    align,
    useHTML = false,
    allowDecimals = true,
    baselineWidth,
    gridlineWidth,
    dualAxisId,
    min,
    max,
  } = props;

  const isX = position === 'bottom' || position === 'top';
  const axisType = isX ? 'xAxis' : 'yAxis';
  const existingAxis = options[axisType] as AxisOptions;
  const isDualAxis = dualAxisId != null && existingAxis != null && existingAxis.id !== dualAxisId;

  const axisObject: AxisOptions = {
    id: dualAxisId, // If the id is unique, treat it as a dual x- or y-axis
    lineColor: GRID_LINE_COLOR,
    tickColor: GRID_LINE_COLOR,
    type: type || 'linear',
    opposite: (isX && position === 'top') || (!isX && position === 'right'),
    startOfWeek: 0,
    allowDecimals,
    title: {
      text: title,
      margin: 14,
      style: {
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '-0.41px',
      },
    },
    accessibility: {
      rangeDescription: description,
    },
    min,
    max,
  };

  if (crosshair === 'line') {
    axisObject.crosshair = {
      color: CROSSHAIR_LINE_COLOR,
      dashStyle: 'Dash',
      width: 2,
    };
  } else if (crosshair === 'rect') {
    axisObject.crosshair = {
      color: CROSSHAIR_BACKGROUND,
    };
  }

  if (tickAmount != null) {
    axisObject.tickAmount = tickAmount;
  }
  if (tickPixelInterval != null) {
    axisObject.tickPixelInterval = tickPixelInterval;
  }
  if (tickPositions != null) {
    axisObject.tickPositions = tickPositions;
  }
  if (labelFormat) {
    set(axisObject, 'labels.formatter', labelFormat);
  }
  if (baselineWidth != null) {
    axisObject.lineWidth = baselineWidth;
  }
  if (gridlineWidth != null) {
    axisObject.gridLineWidth = gridlineWidth;
  }
  if (tickmarkPlacement) {
    axisObject.tickmarkPlacement = tickmarkPlacement;
  }
  axisObject.opposite = opposite;
  axisObject.reversed = reversed;
  axisObject.tickLength = tickLength;
  axisObject.tickWidth = tickWidth;
  set(axisObject, 'labels.align', align);
  set(axisObject, 'labels.distance', labelDistance);
  set(axisObject, 'labels.style.fontSize', '11px');
  set(axisObject, 'labels.useHTML', useHTML);
  set(axisObject, 'labels.style.whiteSpace', labelsNoWrap ? 'nowrap' : undefined);

  if (plotLines) {
    axisObject.plotLines = plotLines;
  }

  if (categories) {
    axisObject.categories = categories;
  }

  if (!isX) {
    axisObject.gridLineColor = GRID_LINE_COLOR;
  }

  return {
    ...options,
    [axisType]: isDualAxis ? configureDualAxis(parentProps, existingAxis, axisObject) : axisObject,
  };
};

const Axis = (props: AxisProps) => {
  const { id, registerChild } = useFlareContext();

  useEffect(() => {
    registerChild(id, (options, parentProps) => {
      if (parentProps.isLoading) {
        return {};
      }
      return buildAxisOptions(options, parentProps, props);
    });
  }, [props]);

  return null;
};

export default Axis;
