import { Plural, Trans } from '@lingui/react/macro';
import { Space } from 'antd';
import { useMemo } from 'react';

import { CompanyDetailType } from '@/api/activity';
import CompanyLogo from '@/app/web-traffic-activity/CompanyLogo';
import { getWebTrafficFieldName } from '@/app/web-traffic-activity/webTrafficFields';
import { Flex } from '@/components/Flex';
import Table, { ColumnsType } from '@/components/Table';
import { Tag, Text } from '@/components/typography';
import { AUDIENCE_PRIMARY, TAM_PRIMARY } from '@/constants/colors';
import SolAPIError from '@/error/SolAPIError';
import { numberFormat } from '@/helper/numberFormatter';
import { getRevenueRangeDescription } from '@/messages/revenue-range-name-messages';

type Props = {
  data?: CompanyDetailType[];
  isLoading?: boolean;
  error: SolAPIError | null;
};

const WebTrafficCompanyActivityTable = ({ data, isLoading, error }: Props) => {
  const columns = useMemo<ColumnsType<CompanyDetailType>>(
    () => [
      {
        title: getWebTrafficFieldName('name'),
        key: 'name',
        fixed: 'left',
        width: 150,
        render: (text, field) => (
          <Space>
            <CompanyLogo size="small" name={field.name} domain={field.tld} />
            <Text variant="caption1">{field.name || field.tld}</Text>
          </Space>
        ),
      },
      {
        title: getWebTrafficFieldName('region.id'),
        key: 'region.id',
        width: 110,
        render: (text, field) => field.region?.name,
      },
      {
        title: getWebTrafficFieldName('sector.id'),
        key: 'sector.id',
        width: 110,
        render: (text, field) => field.sector?.name,
      },
      {
        title: getWebTrafficFieldName('revRange.id'),
        key: 'revRange.id',
        align: 'right',
        width: 110,
        render: (text, field) => getRevenueRangeDescription(field.revRange?.id),
      },
      {
        title: getWebTrafficFieldName('audienceIds'),
        key: 'audienceIds',
        align: 'right',
        width: 130,
        render: (text, field) => (
          <Flex gap="xsmall" justify="end">
            {field.audiences.length > 0 && (
              <Tag size="medium" color={AUDIENCE_PRIMARY} noMargin>
                <Plural value={field.audiences.length} one="# Audience" other="# Audiences" />
              </Tag>
            )}
            {field.inTam && (
              <Tag size="medium" color={TAM_PRIMARY} noMargin>
                <Trans>TAM</Trans>
              </Tag>
            )}
          </Flex>
        ),
      },
      {
        title: getWebTrafficFieldName('metrics.impressions'),
        key: 'metrics.impressions',
        align: 'right',
        width: 95,
        render: (text, field) => numberFormat(field.metrics?.impressions),
      },
      {
        title: getWebTrafficFieldName('metrics.visits'),
        key: 'metrics.visits',
        align: 'right',
        width: 65,
        render: (text, field) => numberFormat(field.metrics?.visits),
      },
    ],
    [],
  );

  return (
    <div style={{ padding: 8 }}>
      <Table<CompanyDetailType>
        columns={columns}
        dataSource={data}
        loading={isLoading}
        emptyMessage={<Trans>No company activity found</Trans>}
        error={error}
        rowKey={(record) => record.id}
        sticky
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default WebTrafficCompanyActivityTable;
