import classNames from 'classnames';
import { ReactNode } from 'react';

import Markdown from '@/components/page/PageHeader/ai/Markdown';
import { Text } from '@/components/typography';

import styles from './AIMessage.module.scss';

type LoadingProps = {
  type: 'loading';
  children: ReactNode;
};

type OtherProps = {
  type: 'ai' | 'user' | 'error';
  children: string;
};

type Props = (LoadingProps | OtherProps) & {
  className?: string;
};

const AIMessage = ({ className, type, children }: Props) => {
  const content = type === 'ai' ? <Markdown>{children}</Markdown> : children;

  return (
    <Text className={classNames(styles.message, styles[`type-${type}`], className)} variant="body1">
      {content}
    </Text>
  );
};

export default AIMessage;
