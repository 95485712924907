import { print } from 'graphql';

import SolAPIError from '@/error/SolAPIError';

import fetch from './Fetcher';
import { ISolConfig, ISolOptions, ISolRequest, SolResponse } from './solType';

export const fetcherSol = async <VariablesType, JSONResponse>(
  config: ISolConfig<VariablesType>,
  options?: ISolOptions,
): Promise<JSONResponse> => {
  const { query, variables } = config;

  if (!query) {
    return null as JSONResponse;
  }

  const result = await fetch.requestSol<ISolRequest<VariablesType>, SolResponse<JSONResponse>>({
    url: 'graphql',
    method: 'POST',
    data: {
      // turn AST into string if it hasn't been done yet
      query: typeof query === 'string' ? query : print(query),
      variables,
    },
    signal: options?.signal,
  });

  const { data, errors } = result.data;

  if (errors) {
    const requestId = result.headers['x-request-id'];
    const solError = new SolAPIError(requestId, errors);
    console.error(solError.toString());
    throw solError;
  }

  return data;
};
