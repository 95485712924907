import { OPPORTUNITY_TYPE, OpportunityType } from '@/api/opportunity';
import { FlareError } from '@/components/Flare';
import { QueryState } from '@/components/InstantSearch';
import { DateRangeType } from '@/components/page';

import OpportunityInfluenceChart from './OpportunityInfluenceChart';
import OpportunityInfluenceTable from './OpportunityInfluenceTable';
import { OpportunitiesTrend } from './types';

type Props = {
  tableState: Omit<QueryState, 'search' | 'filters'>;
  opportunityType: OPPORTUNITY_TYPE;
  opportunitiesTimeseriesData: OpportunitiesTrend[] | undefined;
  opportunitiesTimeseriesIsLoading?: boolean;
  opportunitiesTimeseriesError?: FlareError;
  opportunitiesListData: OpportunityType[] | undefined;
  opportunitiesListTotalResults?: number;
  opportunitiesListIsLoading?: boolean;
  opportunitiesListError: Error | null;
  onSelectedDateRangeChange(dateRange?: DateRangeType): void;
  onTableStateChange?: (tableState: Omit<QueryState, 'search' | 'filters'>) => void;
};

const OpportunityInfluenceChartTableCombo = ({
  tableState,
  opportunityType,
  opportunitiesTimeseriesData,
  opportunitiesTimeseriesIsLoading,
  opportunitiesTimeseriesError,
  opportunitiesListData,
  opportunitiesListTotalResults,
  opportunitiesListIsLoading,
  opportunitiesListError,
  onSelectedDateRangeChange,
  onTableStateChange,
}: Props) => {
  return (
    <div>
      <OpportunityInfluenceChart
        data={opportunitiesTimeseriesData}
        isLoading={opportunitiesTimeseriesIsLoading}
        error={opportunitiesTimeseriesError}
        opportunityType={opportunityType}
        onSelectedDateRangeChange={onSelectedDateRangeChange}
      />
      <OpportunityInfluenceTable
        tableState={tableState}
        data={opportunitiesListData}
        totalResults={opportunitiesListTotalResults}
        isLoading={opportunitiesListIsLoading}
        error={opportunitiesListError}
        opportunityType={opportunityType}
        onTableStateChange={onTableStateChange}
      />
    </div>
  );
};

export default OpportunityInfluenceChartTableCombo;
