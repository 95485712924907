import gql from 'graphql-tag';
import { useMemo } from 'react';

import { useSolQuery } from '@/hooks/useSolQuery';

import { RecentDealVisitTrendsGQLResponse, RecentDealsGQLResponse } from './opportunityType';

export const useRecentDeals = () => {
  const { data, error, isLoading } = useSolQuery<RecentDealsGQLResponse>({
    query: gql`
      query Opportunities {
        opportunities {
          recentDeals {
            id
            name
            account {
              company {
                name
              }
              visits
              people
              vendors
            }
            openDate
            closeDate
            daysToClose
            amount
          }
        }
      }
    `,
  });

  return {
    recentDeals: data?.opportunities.recentDeals,
    isLoading,
    error,
  };
};

export const useRecentDealVisitTrends = (opportunityId?: string) => {
  const { data, error, isLoading } = useSolQuery<RecentDealVisitTrendsGQLResponse>({
    query:
      !!opportunityId &&
      gql`
        query RecentDealVisitTrends($opportunityId: String) {
          visualization {
            recentDealsTrends(opportunityId: $opportunityId) {
              opportunity {
                id
                name
                openDate
                closeDate
                amount
              }
              channelData {
                channel {
                  id
                  name
                  color
                }
                data {
                  sundayOfWeek
                  runningTotalVisits
                }
              }
            }
          }
        }
      `,
    variables: {
      opportunityId,
    },
  });

  useMemo(() => {
    const { recentDealsTrends } = data?.visualization ?? {};

    if (!recentDealsTrends) {
      return;
    }

    recentDealsTrends.channelData.sort((a, b) => {
      if (a.channel.name < b.channel.name) {
        return -1;
      }
      if (a.channel.name > b.channel.name) {
        return 1;
      }
      return 0;
    });

    return recentDealsTrends;
  }, [data]);

  return {
    recentDealVisitTrends: data?.visualization.recentDealsTrends,
    isLoading,
    error,
  };
};

export const useOpportunityNonDigitalEventsTrends = (opportunityId?: string) => {
  const isLoading = !opportunityId;

  return {
    data: !isLoading
      ? [
          {
            channel: {
              id: 'chnl_activities',
              name: 'Activities',
            },
            vendor: {
              id: 'vndr_activities_calls',
              name: 'Calls',
            },
            data: [
              {
                sundayOfWeek: '2023-08-13T00:00:00.000Z',
                events: [
                  { id: '1', date: '2023-08-14T00:00:00.000Z' },
                  { id: '2', date: '2023-08-14T00:00:00.000Z' },
                  { id: '3', date: '2023-08-15T00:00:00.000Z' },
                ],
              },
              {
                sundayOfWeek: '2023-08-20T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-27T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-03T00:00:00.000Z',
                events: [{ id: '4', date: '2023-09-05T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-09-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-01T00:00:00.000Z',
                events: [
                  { id: '5', date: '2023-10-01T00:00:00.000Z' },
                  { id: '6', date: '2023-10-04T00:00:00.000Z' },
                ],
              },
              {
                sundayOfWeek: '2023-10-08T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-15T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-22T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-29T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-05T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-12T00:00:00.000Z',
                events: [{ id: '7', date: '2023-11-15T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-11-19T00:00:00.000Z',
                events: [
                  { id: '8', date: '2023-11-20T00:00:00.000Z' },
                  { id: '9', date: '2023-11-20T00:00:00.000Z' },
                ],
              },
              {
                sundayOfWeek: '2023-11-26T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-03T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-31T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2024-01-07T00:00:00.000Z',
                events: [],
              },
            ],
          },
          {
            channel: {
              id: 'chnl_campaigns',
              name: 'Campaigns',
            },
            vendor: {
              id: 'vndr_campaigns_emails',
              name: 'Emails',
            },
            data: [
              {
                sundayOfWeek: '2023-08-13T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-20T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-27T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-03T00:00:00.000Z',
                events: [
                  { id: '60', date: '2023-09-04T00:00:00.000Z' },
                  { id: '61', date: '2023-09-08T00:00:00.000Z' },
                ],
              },
              {
                sundayOfWeek: '2023-09-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-01T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-08T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-15T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-22T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-29T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-05T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-12T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-19T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-26T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-03T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-31T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2024-01-07T00:00:00.000Z',
                events: [],
              },
            ],
          },
          {
            channel: {
              id: 'chnl_campaigns',
              name: 'Campaigns',
            },
            vendor: {
              id: 'vndr_campaigns_event',
              name: 'Event',
            },
            data: [
              {
                sundayOfWeek: '2023-08-13T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-20T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-27T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-03T00:00:00.000Z',
                events: [{ id: '50', date: '2023-09-03T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-09-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-01T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-08T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-15T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-22T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-29T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-05T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-12T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-19T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-26T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-03T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-31T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2024-01-07T00:00:00.000Z',
                events: [],
              },
            ],
          },
          {
            channel: {
              id: 'chnl_activities',
              name: 'Activities',
            },
            vendor: {
              id: 'vndr_activities_emails',
              name: 'Emails',
            },
            data: [
              {
                sundayOfWeek: '2023-08-13T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-20T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-27T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-03T00:00:00.000Z',
                events: [{ id: '40', date: '2023-09-03T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-09-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-17T00:00:00.000Z',
                events: [{ id: '41', date: '2023-09-18T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-09-24T00:00:00.000Z',
                events: [{ id: '42', date: '2023-09-29T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-10-01T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-08T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-15T00:00:00.000Z',
                events: [{ id: '43', date: '2023-10-15T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-10-22T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-29T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-05T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-12T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-19T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-26T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-03T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-31T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2024-01-07T00:00:00.000Z',
                events: [],
              },
            ],
          },
          {
            channel: {
              id: 'chnl_activities',
              name: 'Activities',
            },
            vendor: {
              id: 'vndr_activities_meetings',
              name: 'Meetings',
            },
            data: [
              {
                sundayOfWeek: '2023-08-13T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-20T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-27T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-03T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-01T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-08T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-15T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-22T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-29T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-05T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-12T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-19T00:00:00.000Z',
                events: [{ id: '30', date: '2023-11-23T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-11-26T00:00:00.000Z',
                events: [{ id: '31', date: '2023-11-27T00:00:00.000Z' }],
              },
              {
                sundayOfWeek: '2023-12-03T00:00:00.000Z',
                events: [
                  { id: '32', date: '2023-12-03T00:00:00.000Z' },
                  { id: '33', date: '2023-12-03T00:00:00.000Z' },
                ],
              },
              {
                sundayOfWeek: '2023-12-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-31T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2024-01-07T00:00:00.000Z',
                events: [],
              },
            ],
          },
          {
            channel: {
              id: 'chnl_leads',
              name: 'Leads',
            },
            vendor: {
              id: 'vndr_leads_mql',
              name: 'MQL',
            },
            data: [
              {
                sundayOfWeek: '2023-08-13T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-20T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-08-27T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-03T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-09-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-01T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-08T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-15T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-22T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-10-29T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-05T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-12T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-19T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-11-26T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-03T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-10T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-17T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-24T00:00:00.000Z',
                events: [],
              },
              {
                sundayOfWeek: '2023-12-31T00:00:00.000Z',
                events: [
                  { id: '20', date: '2023-12-31T00:00:00.000Z' },
                  { id: '21', date: '2023-12-31T00:00:00.000Z' },
                  { id: '22', date: '2023-12-31T00:00:00.000Z' },
                  { id: '23', date: '2023-12-31T00:00:00.000Z' },
                  { id: '24', date: '2023-12-31T00:00:00.000Z' },
                  { id: '25', date: '2023-12-31T00:00:00.000Z' },
                ],
              },
              {
                sundayOfWeek: '2024-01-07T00:00:00.000Z',
                events: [
                  { id: '26', date: '2024-01-07T00:00:00.000Z' },
                  { id: '27', date: '2024-01-08T00:00:00.000Z' },
                ],
              },
            ],
          },
        ]
      : undefined,
    isLoading,
    error: null,
  };
};
