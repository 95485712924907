import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { OpportunityType, useRecentDeals } from '@/api/opportunity';
import ClosedWonContent from '@/app/closed-won/ClosedWonContent';
import Error404 from '@/components/errors/Error404';
import PageError from '@/components/errors/PageError';
import { Page } from '@/components/page';
import { Link, Text, Title } from '@/components/typography';
import { ROUTES } from '@/router';

import ClosedWonKPIMetrics from './ClosedWonKPIMetrics';
import ClosedWonSelect from './ClosedWonSelect';

const getDefaultRecentDeal = (recentDeals?: OpportunityType[], opportunityId?: string) => {
  if (!recentDeals?.length) {
    return undefined;
  }

  if (opportunityId) {
    const deal = recentDeals.find((d) => d.id === opportunityId);
    return deal || undefined;
  }
  return recentDeals[0];
};

const ClosedWonOverview = () => {
  const navigate = useNavigate();
  const { opportunity: opportunityId } = useParams();
  const { recentDeals, isLoading, error } = useRecentDeals();
  const [selectedDeal, setSelectedDeal] = useState<OpportunityType>();
  const defaultDeal = getDefaultRecentDeal(recentDeals, opportunityId);
  const defaultDeal404 = defaultDeal == null;
  const deal = selectedDeal || defaultDeal;

  useEffect(() => {
    if (deal) {
      navigate(ROUTES.closedWonById(deal.id), { replace: true });
    }
  }, [deal]);

  return (
    <Page title={t`Analytics`} pageName={t`Closed Won`}>
      <Space direction="vertical" size="large">
        {!recentDeals?.length && !isLoading ? (
          <PageError
            message={<Trans>No Closed Won opportunities were found.</Trans>}
            detail={
              <Trans>
                Please{' '}
                <Link variant="headline" to={ROUTES.integrations.path}>
                  connect your Salesforce CRM
                </Link>{' '}
                to see Closed Won opportunities.
              </Trans>
            }
          />
        ) : (
          <>
            <Row justify="space-between" align="bottom">
              <Col>
                <Space direction="vertical" size={4}>
                  {deal?.account?.company.name && (
                    <Title level="3">{deal?.account.company.name}</Title>
                  )}
                  <Text variant="body1">
                    <Trans>Opportunity name: {deal?.name}</Trans>
                  </Text>
                </Space>
              </Col>
              <Col>
                <ClosedWonSelect value={deal} onChange={setSelectedDeal} />
              </Col>
            </Row>
            {!isLoading && defaultDeal404 ? (
              <Error404
                message={
                  <Trans>The Closed Won opportunity you're looking for does not exist</Trans>
                }
              />
            ) : (
              <>
                <ClosedWonKPIMetrics data={deal} isLoading={isLoading} error={error} />
                <ClosedWonContent opportunity={deal} />
              </>
            )}
          </>
        )}
      </Space>
    </Page>
  );
};

export default ClosedWonOverview;
