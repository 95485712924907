import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { TableRef } from 'antd/es/table';
import { useMemo, useRef, useState } from 'react';

import { TRAFFIC_TYPES } from '@/api/common';
import { VendorActivityDataType } from '@/api/vendor';
import ChannelFilter from '@/app/vendors/ChannelFilter';
import VendorActivityTableActionMenu from '@/app/vendors/VendorActivityTableActionMenu';
import VendorFilter from '@/app/vendors/VendorFilter';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType, ExpandableConfig, FilterDropdown } from '@/components/Table';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { Link } from '@/components/typography';
import SolAPIError from '@/error/SolAPIError';
import { MetricSelector } from '@/features/MetricSelector';
import { VisitActivityLegend } from '@/features/VisitActivityLegend';
import { useTableState } from '@/providers/TableState';
import { ROUTES } from '@/router';

import styles from './VendorActivityTable.module.scss';
import VendorTopCampaignsTable from './VendorTopCampaignsTable';

type Props = {
  data?: VendorActivityDataType[];
  totalResults?: number;
  isLoading?: boolean;
  error: SolAPIError | null;
  disabledSeries: TRAFFIC_TYPES[];
  maxVisits?: number;
  onDisabledSeriesChange: (disabledSeriesType: TRAFFIC_TYPES[]) => void;
};

const VendorActivityTable = ({
  data,
  totalResults,
  isLoading,
  error,
  disabledSeries,
  maxVisits,
  onDisabledSeriesChange,
}: Props) => {
  const {
    pageFilters: { audience },
  } = usePageFilterContext();

  const { columns } = useTableState<VendorActivityDataType>();

  const tableRef = useRef<TableRef>(null);
  const [metricSelectorOpen, setMetricSelectorOpen] = useState(false);

  const handleScrollChange = () => {
    if (tableRef.current) {
      const scrollLeft =
        tableRef.current.nativeElement.querySelector('.ant-table-body')?.scrollLeft;
      tableRef.current.nativeElement
        .querySelectorAll('.ant-table-expanded-row-fixed .ant-table-content')
        .forEach((el) => {
          el.scrollLeft = scrollLeft ?? 0;
        });
    }
  };

  const handleNestedScrollChange = (e: React.UIEvent<HTMLDivElement>) => {
    if (tableRef.current) {
      const scrollContainer = tableRef.current.nativeElement.querySelector('.ant-table-body');
      if (scrollContainer) {
        scrollContainer.scrollLeft = e.currentTarget.scrollLeft;
      }
    }
  };

  const sortFields = ROUTES.vendors.searchParams.sortBy.values;

  const allColumns = useMemo<ColumnsType<VendorActivityDataType>>(() => {
    return [
      {
        title: t`Channel`,
        key: 'channel.id',
        fixed: 'left',
        sorter: sortFields.includes('channel.id'),
        width: 120,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <ChannelFilter />
          </FilterDropdown>
        ),
        render: (text, field) => (
          <Link variant="caption1" to={ROUTES.channelById(field.vendor.channel?.id)}>
            {field.vendor.channel?.name}
          </Link>
        ),
      },
      {
        title: t`Vendor`,
        key: 'vendorId',
        fixed: 'left',
        sorter: sortFields.includes('vendorId'),
        width: 200,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <VendorFilter />
          </FilterDropdown>
        ),
        render: (_, field) => (
          <Link variant="caption1" weight="bold" to={ROUTES.vendorById(field.vendor.id)}>
            {field.vendor.name}
          </Link>
        ),
      },
      ...columns,
    ];
  }, [columns]);

  const expandable = useMemo<ExpandableConfig<VendorActivityDataType> | undefined>(
    () => ({
      mode: 'accordion',
      rowExpandable: (record) => record.countCampaigns > 1,
      expandedRowRender: (record) => {
        setTimeout(() => {
          handleScrollChange();
        }, 0);
        return (
          <VendorTopCampaignsTable
            vendor={record.vendor}
            audience={audience}
            disabledSeries={disabledSeries}
            maxVisits={maxVisits}
            onScroll={handleNestedScrollChange}
          />
        );
      },
      expandRowByClick: true,
      durationMs: 300,
      columnWidth: 32,
    }),
    [audience, disabledSeries, handleNestedScrollChange, handleScrollChange, tableRef, maxVisits],
  );

  return (
    <Panel
      size="L"
      title={<Trans>Visits By Vendor</Trans>}
      actions={
        <VendorActivityTableActionMenu onOpenMetricSelector={() => setMetricSelectorOpen(true)} />
      }
      verifyC99Tag
      isFullHeight
    >
      <VisitActivityLegend audience={audience} onDisabledSeriesChange={onDisabledSeriesChange} />
      <InstantSearchTable<VendorActivityDataType>
        data-testid="vendors-table"
        className={styles.table}
        ref={tableRef}
        totalResults={totalResults}
        loading={isLoading}
        error={error}
        columns={allColumns}
        dataSource={data}
        emptyMessage={<Trans>No vendors found</Trans>}
        pagination={false}
        expandable={expandable}
        rowKey={(record) => record.vendor.id}
        sticky
        scroll={{ x: 1200 }}
        onScroll={handleScrollChange}
      />
      <MetricSelector open={metricSelectorOpen} onClose={() => setMetricSelectorOpen(false)} />
    </Panel>
  );
};

export default VendorActivityTable;
